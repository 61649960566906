import { Box, Dialog } from "@material-ui/core";
import { ReactNode } from "react";
import { OnboardingDialogAccessControlIntegration } from "./onboarding-dialog-access-control-integration";
import { OnboardingDialogActions } from "./onboarding-dialog-actions";
import { OnboardingDialogAnnotation } from "./onboarding-dialog-annotation";
import { OnboardingDialogBuilding } from "./onboarding-dialog-building";
import { OnboardingDialogDescription } from "./onboarding-dialog-description";
import { OnboardingDialogLogo } from "./onboarding-dialog-logo";
import { OnboardingDialogPlan } from "./onboarding-dialog-plan";
import { X } from "src/icons/x";
import { LanguagePopover } from "src/components/molecules/language-popover";
import { useTranslation } from "react-i18next";
import { useSettings } from "src/contexts/settings-context";
import { OnboardingDialogTitle } from "./onboarding-dialog-title";
import { OnboardingDialogStepIndicator } from "./onboarding-dialog-step-indicator";
import { OnboardingDialogUsers } from "./onboarding-dialog-users";
import { OnboardingDialogSuccess } from "./onboarding-dialog-success";
import { OnboardingDialogSpots } from "./onboarding-dialog-spots";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  canCancelOnboardingProcess?: boolean;
}
export const OnboardingDialogWrapper = ({
  isOpen,
  onClose,
  children,
  canCancelOnboardingProcess,
}: Props) => {
  const { i18n } = useTranslation();

  const { settings, saveSettings } = useSettings();

  const handleLanguageChange = (language: "en" | "pl"): void => {
    i18n.changeLanguage(language);
    saveSettings({
      ...settings,
      language,
    });
  };

  return (
    <Dialog
      onClose={undefined}
      open={isOpen}
      onBackdropClick={null}
      PaperProps={{
        sx: {
          background: "#fff",
          width: "400px",
        },
      }}
      BackdropProps={{
        sx: {
          width: "100%",
          backgroundColor: "#000",
        },
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          pt: 2,
          px: 2,
          gap: 1,
        }}>
        <LanguagePopover
          language={i18n.language}
          onLanguageChange={handleLanguageChange}
          textMode
        />
        {canCancelOnboardingProcess ? (
          <Box onClick={onClose} sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
            <X sx={{ color: "#A0A19B" }} />
          </Box>
        ) : null}
      </Box>
      {children}
    </Dialog>
  );
};

OnboardingDialogWrapper.Logo = OnboardingDialogLogo;
OnboardingDialogWrapper.Description = OnboardingDialogDescription;
OnboardingDialogWrapper.Title = OnboardingDialogTitle;
OnboardingDialogWrapper.Annotation = OnboardingDialogAnnotation;
OnboardingDialogWrapper.StepIndicator = OnboardingDialogStepIndicator;
OnboardingDialogWrapper.AccessControlIntegration = OnboardingDialogAccessControlIntegration;
OnboardingDialogWrapper.Actions = OnboardingDialogActions;
OnboardingDialogWrapper.Plan = OnboardingDialogPlan;
OnboardingDialogWrapper.Users = OnboardingDialogUsers;
OnboardingDialogWrapper.Building = OnboardingDialogBuilding;
OnboardingDialogWrapper.Success = OnboardingDialogSuccess;
OnboardingDialogWrapper.Spots = OnboardingDialogSpots;
