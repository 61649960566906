import { Box, Button, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { usePopover } from "src/hooks/use-popover";
import { ChevronDown as ChevronDownIcon } from "src/icons/chevron-down";
import useStore from "src/hooks/use-store";
import { observer } from "mobx-react-lite";
import { useSiteAdminsProfile } from "src/features/site-management/hooks/api/admins/use-site-admins-profile";
import { useTranslation } from "react-i18next";
import { lightPrimary } from "src/colors";
import { useQueryClient } from "@tanstack/react-query";
import { OnboardingDialog } from "./onboarding/onboarding-dialog";
import { useDialog } from "src/hooks/use-dialog";
import { OrganizationRegistrationType } from "src/types/enum/organization-registration-type";
import { Plus } from "src/icons/plus";
import { Can } from "src/permissions/can";
import { useNavigate } from "react-router-dom";

const SiteSelectorComponent = () => {
  const { user } = useStore();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  const siteProfileQuery = useSiteAdminsProfile();

  const selectedSite = siteProfileQuery.data?.sites.find(site => site.token === user.siteToken);

  const [isOnboardingDialogOpen, handleOpenOnboardingDialog, handleCloseOnboardingDialog] =
    useDialog();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: {
            sm: "center",
            md: "flex-start",
          },
          flexDirection: {
            sm: "row",
            md: "column",
          },
          justifyContent: {
            sm: "flex-start",
            md: "space-between",
          },
          gap: {
            sm: "10px",
            md: "0",
          },
        }}>
        <>
          <Button
            color="primary"
            onClick={handleOpen}
            ref={anchorRef}
            size="large"
            variant="text"
            endIcon={<ChevronDownIcon fontSize="small" />}>
            {selectedSite?.name ?? "---"}
          </Button>

          <Menu
            anchorEl={anchorRef.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={open}
            onClose={handleClose}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}>
            {siteProfileQuery.data?.sites.map(site => {
              return (
                <MenuItem
                  key={site.id}
                  onClick={() => {
                    user.setSiteToken(site.token);

                    handleClose();
                    queryClient.invalidateQueries();
                    navigate("/statistics");
                  }}>
                  {site.name}
                </MenuItem>
              );
            })}
          </Menu>
        </>
        <Can I="create" a="Sites" passThrough>
          {allowed => (
            <Tooltip title={t("common:notSufficientPermissions")} disableHoverListener={allowed}>
              <span>
                <Button
                  onClick={handleOpenOnboardingDialog}
                  size="small"
                  variant="text"
                  disabled={!allowed}
                  startIcon={<Plus />}
                  sx={{
                    color: lightPrimary.hoverOutlined,
                    pb: 0,
                    px: 0,
                    borderRadius: "unset",
                    borderBottom: `1px solid ${lightPrimary.hoverOutlined}`,
                    ":hover": { color: "#65A5BA", borderColor: `#65A5BA` },
                    ":disabled": { color: " #8E8E8E", borderColor: `#8E8E8E` },
                  }}>
                  {t("sites:addNewSiteButton")}
                </Button>
              </span>
            </Tooltip>
          )}
        </Can>
      </Box>
      <OnboardingDialog
        isOpen={isOnboardingDialogOpen}
        onClose={handleCloseOnboardingDialog}
        canCancelOnboardingProcess
        role={OrganizationRegistrationType.SITE_OWNER}
      />
    </>
  );
};

export const SiteSelector = observer(SiteSelectorComponent);
