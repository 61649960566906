import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { lightPrimary } from "src/colors";

import { SuccessCircle } from "src/icons/success-circle";

export const OnboardingDialogSuccess = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pb: 3.75,
        pt: 5,
      }}>
      <SuccessCircle sx={{ width: "55px", height: "55px" }} />
      <Typography
        color="black"
        sx={{
          pt: 3.75,
          pb: 1.85,
        }}>
        {t("onboarding:success.title")}
      </Typography>
      <Typography color="black" sx={{ display: "flex", gap: 0.5 }}>
        {t("onboarding:success.youAre")}
        <span
          style={{
            color: lightPrimary.hoverOutlined,
            fontWeight: "bold",
          }}>
          {t("onboarding:success.goodToGo")}
        </span>
      </Typography>
    </Box>
  );
};
