import { Typography } from "@material-ui/core";

interface Props {
  text: string;
}

export const OnboardingDialogTitle = ({ text }: Props) => {
  return (
    <Typography
      color="black"
      align="center"
      variant="subtitle1"
      sx={{ py: 4, pb: 3, fontSize: "14px", mx: "auto", fontWeight: "500" }}>
      {text}
    </Typography>
  );
};
