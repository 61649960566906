import { useQuery } from "@tanstack/react-query";
import { siteEvChargersModuleAccessApi } from "../../api/lib/site-ev-chargers-module-access";
import { siteEvChargersModuleAccessKeys } from "../../api/query-keys/site-ev-chargers-module-access";
import { useAuth } from "src/contexts/auth-provider";

export const useSiteEvChargersModuleAccess = () => {
  const { session } = useAuth();

  return useQuery({
    queryFn: siteEvChargersModuleAccessApi.getAccess,
    queryKey: siteEvChargersModuleAccessKeys.all,
    enabled: !!session,
  });
};
