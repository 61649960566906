import { DialogContent } from "@material-ui/core";
import { OnboardingDialogWrapper } from "src/components/organisms/onboarding/onboarding-dialog-wrapper";

interface Props {
  onStart: () => void;
}

export const SuccessStep = ({ onStart }: Props) => {
  return (
    <>
      <OnboardingDialogWrapper.Logo />
      <DialogContent sx={{ px: 4.3 }}>
        <OnboardingDialogWrapper.Success />
      </DialogContent>
      <OnboardingDialogWrapper.Actions onStart={onStart} />
    </>
  );
};
