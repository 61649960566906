import store from "../../store/application-store";
import { InternalAxiosRequestConfig } from "axios";

export const attachOrganizationToken = (config: InternalAxiosRequestConfig) => {
  const { user } = store;

  if (user.userOrganizationId) {
    config.headers["s-organization-token"] = user.userOrganizationId;
  }

  return config;
};
