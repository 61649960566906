import { FC } from "react";

import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";

import { usePopover } from "../../hooks/use-popover";

import { ChevronDown as ChevronDownIcon } from "../../icons/chevron-down";
import { Logout as LogoutIcon } from "../../icons/logout";
import { StickyNote2 as StickyNoteIcon, PrivacyTip as PrivacyIcon } from "@material-ui/icons";

import { observer } from "mobx-react-lite";
import { useAdminOrganizations } from "src/hooks/use-admin-organizations";
import useStore from "src/hooks/use-store";
import { AccountPopoverAvatar } from "../molecules/navbar/account-popover-avatar";
import { useAuth, useUser } from "src/contexts/auth-provider";
import { logout } from "src/api/services/supabase/auth";

interface Props {
  darkMode: boolean;
}

const AccountPopoverComponent: FC<Props> = ({ darkMode, ...other }) => {
  const { i18n, t } = useTranslation();
  const user = useUser();

  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const store = useStore();
  const { session } = useAuth();

  const [{ data }] = useAdminOrganizations(!!session);

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();

      store.user.clearUserOrganizationId();
      store.user.clearSiteToken();
      store.user.clearViewContext();
      logout();
    } catch (err) {
      console.error(err);
      toast.error(t("common:errorMessages.somethingWentWrong"));
    }
  };

  const handleOpenPrivacyPolicy = () => {
    window.open(
      `https://storage.googleapis.com/acs-assets/privacy-policy-${i18n.language}.pdf`,
      "_blank",
    );
  };

  const handleOpenTermsAndConditions = () => {
    window.open(
      `https://storage.googleapis.com/acs-assets/terms-of-use-${i18n.language}.pdf`,
      "_blank",
    );
  };

  const renderAvatar = () => {
    return <AccountPopoverAvatar avatarText={user?.email.charAt(0).toUpperCase()} />;
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          cursor: "pointer",
          display: "flex",
          ml: 2,
        }}
        {...other}>
        {renderAvatar()}
        <Box
          sx={{
            alignItems: "center",
            display: {
              md: "flex",
              xs: "none",
            },
            flex: 1,
            ml: 1,
            minWidth: 120,
          }}>
          <div>
            <Typography sx={{ color: "text.primary" }} variant="subtitle2">
              {user?.email}
            </Typography>
          </div>
          <ChevronDownIcon
            sx={{
              color: "primary.dark",
              ml: 1,
            }}
          />
        </Box>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            minWidth: 260,
            display: "flex",
            flexDirection: "column",
          },
        }}>
        <List sx={{ p: 0 }}>
          <ListItem>
            <ListItemAvatar>{renderAvatar()}</ListItemAvatar>
            <ListItemText
              primary={user?.email}
              secondary={
                data?.organizations?.find(
                  organization => organization.token === store.user.userOrganizationId,
                )?.name
              }
            />
          </ListItem>
          <ListItem button onClick={handleOpenTermsAndConditions}>
            <ListItemIcon>
              <PrivacyIcon />
            </ListItemIcon>
            <ListItemText primary={t("common:termsAndConditions")} />
          </ListItem>
          <ListItem button onClick={handleOpenPrivacyPolicy}>
            <ListItemIcon>
              <StickyNoteIcon />
            </ListItemIcon>
            <ListItemText primary={t("common:privacyPolicy")} />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={t("common:logout")} />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export const AccountPopover = observer(AccountPopoverComponent);
