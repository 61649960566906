import axios from "axios";

import { attachAcceptLanguage } from "src/api/interceptors/attach-accept-language";
import { attachAccessToken } from "src/api/interceptors/attach-access-token";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { refreshAccessTokenLogic } from "src/api/interceptors/refresh-access-token-logic";
import { attachOrganizationToken } from "../interceptors/attach-organization-token";

const tenantApiV1 = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/organization-admin-api/v1`,
});

createAuthRefreshInterceptor(tenantApiV1, refreshAccessTokenLogic);

tenantApiV1.interceptors.request.use(attachAccessToken);
tenantApiV1.interceptors.request.use(attachOrganizationToken);
tenantApiV1.interceptors.request.use(attachAcceptLanguage);

export default tenantApiV1;
