import { Typography } from "@material-ui/core";
import { SxProps } from "@material-ui/system";

interface Props {
  text: string;
  sx?: SxProps;
}

export const OnboardingDialogDescription = ({ text, sx }: Props) => {
  return (
    <Typography
      variant="body2"
      color="black"
      align="center"
      sx={{ fontSize: "12px", mx: "auto", ...sx, maxWidth: "251px", pt: 4 }}>
      {text}
    </Typography>
  );
};
