export const SpotsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2 12.9C13.6912 12.9 14.9 11.6912 14.9 10.2C14.9 8.70883 13.6912 7.5 12.2 7.5C10.7088 7.5 9.5 8.70883 9.5 10.2C9.5 11.6912 10.7088 12.9 12.2 12.9Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2 21C15.8 17.4 19.4 14.1764 19.4 10.2C19.4 6.22355 16.1764 3 12.2 3C8.22355 3 5 6.22355 5 10.2C5 14.1764 8.6 17.4 12.2 21Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
