import { useQuery } from "@tanstack/react-query";
import { adminsProfileKeys } from "src/api/query-keys/admins-profile";
import useStore from "src/hooks/use-store";
import { ApiError } from "src/types/interfaces/api-error";
import { AdminProfile } from "src/types/responses/admin-profile";
import { organizationAdminsApi } from "../../../api/lib/organization-admins";

export const useAdminsProfile = (enabled?: boolean, retry?: boolean) => {
  const { user } = useStore();

  return useQuery<AdminProfile, ApiError>({
    queryKey: adminsProfileKeys.detail(),
    queryFn: organizationAdminsApi.fetchAdminProfile,
    enabled: enabled ?? true,
    retry,
    onError: error => {
      if (error.response.status === 401) {
        user.clearUserOrganizationId();
      }
    },
  });
};
