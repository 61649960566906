import { Suspense, lazy } from "react";
import type { DataRouteObject } from "react-router";

import { LoadingScreen } from "./components/loading-screen";

const Loadable = Component => props =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loadable(
  lazy(() =>
    import("./containers/auth/login").then(module => ({
      default: module.LoginContainer,
    })),
  ),
);

const ForgotPassword = Loadable(
  lazy(() =>
    import("./containers/auth/forgot-password").then(module => ({
      default: module.ForgotPasswordContainer,
    })),
  ),
);

const UpdatePassword = Loadable(
  lazy(() =>
    import("./containers/auth/update-password").then(module => ({
      default: module.UpdatePasswordContainer,
    })),
  ),
);

const SetUpPassword = Loadable(
  lazy(() =>
    import("./containers/auth/set-up-password").then(module => ({
      default: module.SetUpPasswordContainer,
    })),
  ),
);

// Not found page
const NotFound = Loadable(
  lazy(() => import("./containers/not-found").then(module => ({ default: module.NotFound }))),
);

const routes: DataRouteObject[] = [
  {
    id: "login-container",
    path: "login",
    children: [
      {
        id: "login",
        index: true,
        element: <Login />,
      },
    ],
  },
  {
    id: "forgot-password-container",
    path: "forgot-password",
    children: [
      {
        id: "forgot-password",
        index: true,
        element: <ForgotPassword />,
      },
    ],
  },
  {
    id: "set-up-password-container",
    path: "set-up-password",
    children: [
      {
        id: "set-up-password",
        index: true,
        element: <SetUpPassword />,
      },
    ],
  },
  {
    id: "reset-password-container",
    path: "reset-password",
    children: [
      {
        id: "reset-password",
        index: true,
        element: <UpdatePassword />,
      },
    ],
  },
  {
    id: "not-found",
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
