export const ReservationsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 10.1111H4M15.5556 3V6.55556M8.44444 3V6.55556M9.33333 15.4444L11.1111 17.2222L15.1111 13.2222M8.26667 20.7778H15.7333C17.2268 20.7778 17.9735 20.7778 18.544 20.4871C19.0457 20.2315 19.4537 19.8235 19.7094 19.3218C20 18.7513 20 18.0046 20 16.5111V9.04445C20 7.55097 20 6.80423 19.7094 6.2338C19.4537 5.73204 19.0457 5.32409 18.544 5.06843C17.9735 4.77778 17.2268 4.77778 15.7333 4.77778H8.26667C6.77319 4.77778 6.02646 4.77778 5.45603 5.06843C4.95426 5.32409 4.54631 5.73204 4.29065 6.2338C4 6.80423 4 7.55097 4 9.04444V16.5111C4 18.0046 4 18.7513 4.29065 19.3218C4.54631 19.8235 4.95426 20.2315 5.45603 20.4871C6.02646 20.7778 6.77319 20.7778 8.26667 20.7778Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
