import { useEffect } from "react";
import i18n from "../i18n";
import { useSettings } from "src/contexts/settings-context";

export const useUpdateLanguage = () => {
  const { settings } = useSettings();

  useEffect(() => {
    if (settings.language === i18n.language) return;
    i18n.changeLanguage(settings.language);
  }, [settings]);
};
