export const StatisticIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.2 2.6C3.2 2.26863 2.93137 2 2.6 2C2.26863 2 2 2.26863 2 2.6V18.6136C2 20.6018 3.61178 22.2136 5.6 22.2136H21.6136C21.945 22.2136 22.2136 21.945 22.2136 21.6136C22.2136 21.2822 21.945 21.0136 21.6136 21.0136H18.1393V11.7487C18.1393 10.865 17.4229 10.1487 16.5393 10.1487H14.4649C13.5813 10.1487 12.8649 10.865 12.8649 11.7487V21.0136H11.3487V4.95811C11.3487 4.07446 10.6323 3.35811 9.74869 3.35811H7.67434C6.79069 3.35811 6.07434 4.07446 6.07434 4.95811V21.0136H5.6C4.27452 21.0136 3.2 19.9391 3.2 18.6136V2.6ZM7.27434 21.0136H10.1487V4.95811C10.1487 4.7372 9.9696 4.55811 9.74869 4.55811H7.67434C7.45343 4.55811 7.27434 4.7372 7.27434 4.95811V21.0136ZM14.0649 21.0136H16.9393V11.7487C16.9393 11.5278 16.7602 11.3487 16.5393 11.3487H14.4649C14.244 11.3487 14.0649 11.5278 14.0649 11.7487V21.0136Z"
        fill="currentColor"
      />
    </svg>
  );
};
