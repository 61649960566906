import { useContext } from "react";

import { ApplicationStoreContext } from "../contexts/application-store-context";
import { IApplicationStoreContext } from "../types/application-store";

const useStore = () => {
  const store: IApplicationStoreContext = useContext(ApplicationStoreContext);

  if (!store) {
    throw new Error("useStore must be used within a StoreProvider.");
  }

  return store;
};

export default useStore;
