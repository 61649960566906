import { useMemo } from "react";
import { Button, Menu, MenuItem, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { usePopover } from "src/hooks/use-popover";
import { ChevronDown as ChevronDownIcon } from "src/icons/chevron-down";
import { Briefcase as BriefcaseIcon } from "src/icons/briefcase";
import useStore from "src/hooks/use-store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useSetViewContext } from "src/hooks/use-set-view-context";
import { lightActions } from "src/colors";

const PanelContextSelectorComponent = () => {
  const { t } = useTranslation();
  const { user } = useStore();
  const navigate = useNavigate();
  const {
    palette: { mode },
  } = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  const { siteContext, tenantContext } = useSetViewContext();

  const currentViewContext = useMemo(() => {
    switch (user.viewContext) {
      case "site":
        return t("common:viewContextSelector.site");

      case "tenant":
        return t("common:viewContextSelector.tenant");

      default:
        return t("common:viewContextSelector.placeholder");
    }
  }, [user.viewContext, t]);

  const isPanelContextSelectorDisabled = !siteContext || !tenantContext;

  return (
    <>
      <Button
        color="primary"
        disabled={isPanelContextSelectorDisabled}
        onClick={handleOpen}
        ref={anchorRef}
        size={isMobile ? "medium" : "large"}
        variant="text"
        startIcon={
          <BriefcaseIcon
            fontSize="small"
            sx={{
              color: isPanelContextSelectorDisabled
                ? lightActions.disabled
                : mode === "dark"
                ? "#fff"
                : "#000",
              width: "16px",
            }}
          />
        }
        endIcon={<ChevronDownIcon fontSize="small" />}
        sx={{
          ml: 1,
          mr: 2,
          alignItems: "center",
          fontWeight: 400,
        }}>
        {currentViewContext}
      </Button>
      <Menu
        sx={{
          minWidth: "242px",
        }}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <MenuItem
          disabled={!tenantContext}
          onClick={() => {
            navigate("/statistics");

            user.setViewContext("tenant");

            handleClose();
          }}>
          {t("common:viewContextSelector.tenant")}
        </MenuItem>
        <MenuItem
          disabled={!siteContext}
          onClick={() => {
            navigate("/statistics");

            user.setViewContext("site");

            handleClose();
          }}>
          {t("common:viewContextSelector.site")}
        </MenuItem>
      </Menu>
    </>
  );
};

export const PanelContextSelector = observer(PanelContextSelectorComponent);
