import React, { useRef, useState } from "react";

import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";

import type { SxProps } from "@material-ui/system";
import { ChevronDown } from "src/icons/chevron-down";
import { lightNeutral, lightPrimary } from "src/colors";

interface LanguagePopoverProps {
  language: string;
  onLanguageChange: (newLanguage: "en" | "pl") => void;
  sx?: SxProps;
  textMode?: boolean;
}

const languageOptions = {
  en: {
    icon: "/static/en_flag.svg",
    label: "English",
    shortcut: "EN",
  },
  pl: {
    icon: "/static/pl_flag.svg",
    label: "Polish",
    shortcut: "PL",
  },
};

export const LanguagePopover: React.FC<LanguagePopoverProps> = ({
  language,
  onLanguageChange,
  textMode,
  ...other
}) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLanguageChange = (newLanguage: "en" | "pl"): void => {
    onLanguageChange(newLanguage);
    setOpen(false);
  };

  const selectedOption = languageOptions[language];

  return (
    <>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
        {...other}
        sx={{
          boxShadow: textMode ? lightPrimary.borders : "transparent",
          background: textMode ? lightNeutral[100] : "transparent",
          borderRadius: textMode ? "6px" : "inherit",
        }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 20,
            width: textMode ? 40 : 20,
            "& img": {
              width: "100%",
            },
          }}>
          {textMode ? (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Typography variant="subtitle2">{selectedOption.shortcut}</Typography>
              <ChevronDown
                fontSize="small"
                style={{ rotate: open ? "180deg" : "0deg", transition: "all 0.1s linear" }}
              />
            </Box>
          ) : (
            <img alt={selectedOption.label} src={selectedOption.icon} />
          )}
        </Box>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: textMode ? 50 : 240 },
        }}>
        {Object.keys(languageOptions).map((option: "en" | "pl") => (
          <MenuItem onClick={() => handleLanguageChange(option)} key={option}>
            {textMode ? null : (
              <ListItemIcon>
                <Box
                  sx={{
                    display: "flex",
                    height: 20,
                    width: 20,
                    "& img": {
                      width: "100%",
                    },
                  }}>
                  <img alt={languageOptions[option].label} src={languageOptions[option].icon} />
                </Box>
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {textMode ? languageOptions[option].shortcut : languageOptions[option].label}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};
