import { SiteDetails } from "src/features/site/types/responses/site-details";
import { SiteOnboardingPayload } from "../../../../types/payloads/onboarding-payload";
import { IntegrationQuestionnairePayload } from "../../../site-settings/types/payloads/integration-questionnaire";
import { SiteIntegrationQuestionnaireState } from "../../types/enum/site-integration-questionnaire-state";
import { CreateSitePayload } from "../../types/payloads/sites/create-site-payload";
import { ApproveSiteRemoval } from "../../types/responses/approve-site-removal";
import { IntegrationQuestionnaire } from "../../types/responses/integration-questionnaire";
import { IntegrationQuestionnaireQuestion } from "../../types/responses/integration-questionnaire-question";
import { RemoveSite } from "../../types/responses/remove-site";
import { RemoveSiteStatus } from "../../types/responses/remove-site-status";
import { Address, Site } from "../../types/responses/site";
import siteApi from "../clients/site-api";

class SitesApi {
  async fetchSites(): Promise<Site[]> {
    const { data } = await siteApi.get<Site[]>("/sites");

    return data;
  }

  async createSite(values: CreateSitePayload): Promise<Site> {
    const { data } = await siteApi.post<Site>(`/sites`, values);

    return data;
  }

  async updateSite(id: number, name: string, address: Address): Promise<Site> {
    const { data } = await siteApi.patch<Site>(`/sites/${id}`, {
      name,
      ...address,
    });
    return data;
  }

  async fetchSiteDetails(siteId: number): Promise<SiteDetails> {
    const { data } = await siteApi.get<SiteDetails>(`/sites/${siteId}/information`);

    return data;
  }

  async removeSite(siteName: string): Promise<RemoveSite> {
    const { data } = await siteApi.post<RemoveSite>(`/sites/remove`, {
      siteName,
    });

    return data;
  }

  async removeSiteStatus(): Promise<RemoveSiteStatus> {
    const { data } = await siteApi.get<RemoveSiteStatus>(`/sites/remove/status`);

    return data;
  }

  async approveRemoveSite(): Promise<ApproveSiteRemoval> {
    const { data } = await siteApi.post<ApproveSiteRemoval>(`/sites/remove/approve`);

    return data;
  }

  async rejectRemoveSite(): Promise<void> {
    await siteApi.delete<void>(`/sites/remove/reject`);
  }

  async uploadIntegrationQuestionnaireImages(images: FormData): Promise<{ url: string }[]> {
    const { data } = await siteApi.post<{ url: string }[]>(
      `/sites/integration-questionnaire/photos`,
      images,
    );

    return data;
  }

  async fetchIntegrationQuestionnaire(id: number): Promise<IntegrationQuestionnaire> {
    const { data } = await siteApi.get<IntegrationQuestionnaire>(
      `/sites/${id}/integration-questionnaire`,
    );
    return data;
  }

  async fetchIntegrationQuestionnaireQuestions(): Promise<IntegrationQuestionnaireQuestion[]> {
    const { data } = await siteApi.get<IntegrationQuestionnaireQuestion[]>(
      `/sites/integration-questionnaire/questions`,
    );

    return data;
  }

  async sendIntegrationQuestionnaire(
    id: number,
    data: {
      questionnaire: IntegrationQuestionnairePayload[];
      photo1?: string;
      photo2?: string;
      state: SiteIntegrationQuestionnaireState;
    },
  ): Promise<void> {
    await siteApi.post<void>(`/sites/${id}/integration-questionnaire`, data);
  }

  async completeRegistration(values: SiteOnboardingPayload): Promise<void> {
    await siteApi.post(`/sites/complete-registration`, values);
  }
}

export const sitesApi = new SitesApi();
