import { Suspense, lazy } from "react";
import type { DataRouteObject } from "react-router";
import { LoadingScreen } from "../../components/loading-screen";
import { Layout } from "../../layout/main-layout";

import { Navigate } from "react-router-dom";
import { AuthGuard } from "src/components/guards/auth-guard";

const Loadable = Component => props =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const TenantsContainer = Loadable(
  lazy(() =>
    import("../site-tenants/containers/tenants").then(module => ({
      default: module.TenantsContainer,
    })),
  ),
);

const Tenants = Loadable(
  lazy(() =>
    import("../site-tenants/containers/tenants/tenants").then(module => ({
      default: module.TenantsTabContainer,
    })),
  ),
);

const TenantsInvitations = Loadable(
  lazy(() =>
    import("../site-tenants/containers/tenants/invitations").then(module => ({
      default: module.InvitationsContainer,
    })),
  ),
);

const Resource = Loadable(
  lazy(() =>
    import("../site-parking-spots/containers/index").then(module => ({
      default: module.ResourcesContainer,
    })),
  ),
);

const Resources = Loadable(
  lazy(() =>
    import("../site-parking-spots/containers/spots").then(module => ({
      default: module.SpotsContainer,
    })),
  ),
);

const Statistics = Loadable(
  lazy(() =>
    import("../site-statistics/containers").then(module => ({
      default: module.StatisticsContainer,
    })),
  ),
);
const BlackListLicensePlate = Loadable(
  lazy(() =>
    import("../site-blacklisted-plates/containers").then(module => ({
      default: module.BlacklistContainer,
    })),
  ),
);

const Tenant = Loadable(
  lazy(() =>
    import("../site-tenants/containers/tenants/[tenantId]").then(module => ({
      default: module.TenantContainer,
    })),
  ),
);

const TenantRuleGroup = Loadable(
  lazy(() =>
    import("../site-tenants/containers/tenants/tenant-rule-group-details").then(module => ({
      default: module.TenantRuleGroupDetails,
    })),
  ),
);

const SiteManagementContainer = Loadable(
  lazy(() =>
    import("../site-management/containers").then(module => ({
      default: module.SiteManagementContainer,
    })),
  ),
);

const SiteDetails = Loadable(
  lazy(() =>
    import("../site-management/containers/site-details").then(module => ({
      default: module.SiteDetailsContainer,
    })),
  ),
);

const IntegrationQuestionnaireSitesContainer = Loadable(
  lazy(() =>
    import("../site-settings/containers/integration-questionnaire").then(module => ({
      default: module.IntegrationQuestionnaireSitesContainer,
    })),
  ),
);

const IntegrationQuestionnaireFormContainer = Loadable(
  lazy(() =>
    import(
      "../site-settings/containers/integration-questionnaire/integration-questionnaire-form"
    ).then(module => ({
      default: module.IntegrationQuestionnaireFormContainer,
    })),
  ),
);

const SiteIntegrationQuestionnaire = Loadable(
  lazy(() =>
    import(
      "../site-settings/containers/integration-questionnaire/[siteIdIntegrationQuestionnaire]"
    ).then(module => ({
      default: module.SiteIntegrationQuestionnaire,
    })),
  ),
);

const SiteAdmins = Loadable(
  lazy(() =>
    import("../site-management/containers/site-admins").then(module => ({
      default: module.SiteAdminsContainer,
    })),
  ),
);

const Settings = Loadable(
  lazy(() =>
    import("../site-settings/containers").then(module => ({
      default: module.SettingsContainer,
    })),
  ),
);

const ContactForm = Loadable(
  lazy(() =>
    import("../site-settings/containers/contact").then(module => ({
      default: module.ContactContainer,
    })),
  ),
);

const ChangePassword = Loadable(
  lazy(() =>
    import("../site-settings/containers/change-password").then(module => ({
      default: module.ChangePasswordContainer,
    })),
  ),
);

//? TODO: disable billing data fetching because Salesforce throws errors because it is not paid
// const Billing = Loadable(
//   lazy(() =>
//     import("./containers/billing").then(module => ({
//       default: module.BillingContainer,
//     })),
//   ),
// );

// const AllSitesBilling = Loadable(
//   lazy(() =>
//     import("./containers/billing/all-sites-billing").then(module => ({
//       default: module.AllSitesBillingContainer,
//     })),
//   ),
// );

// const BillingDetails = Loadable(
//   lazy(() =>
//     import("./containers/billing/billing-details").then(module => ({
//       default: module.BillingDetailsContainer,
//     })),
//   ),
// );

// const BillingHistory = Loadable(
//   lazy(() =>
//     import("./containers/billing/billingHistory").then(module => ({
//       default: module.BillingHistoryContainer,
//     })),
//   ),
// );

const SiteRemoveStatus = Loadable(
  lazy(() =>
    import("../site-management/containers/remove-site-status").then(module => ({
      default: module.SiteRemoveStatusContainer,
    })),
  ),
);

const ChargingSpotsTabContainer = Loadable(
  lazy(() =>
    import("../site-ev-chargers/containers").then(module => ({
      default: module.ChargingSpotsTabContainer,
    })),
  ),
);

const ChargingSpotsContainer = Loadable(
  lazy(() =>
    import("../site-ev-charging-spots/containers/charging-spots").then(module => ({
      default: module.ChargingSpotsContainer,
    })),
  ),
);

const ChargingSettingsContainer = Loadable(
  lazy(() =>
    import("../site-ev-chargers/containers/settings").then(module => ({
      default: module.ChargingSettingsTabContainer,
    })),
  ),
);

const ChargingUsageLimitsContainer = Loadable(
  lazy(() =>
    import("../site-ev-chargers-usage-limits/containers").then(module => ({
      default: module.ChargingUsageLimitsContainer,
    })),
  ),
);

const ChargingRulesContainer = Loadable(
  lazy(() =>
    import("../site-ev-chargers-rules/containers").then(module => ({
      default: module.ChargerRulesContainer,
    })),
  ),
);

const ChargingElectricityUsageContainer = Loadable(
  lazy(() =>
    import("../site-ev-chargers-electricity-usage/containers").then(module => ({
      default: module.ElectricityUsageContainer,
    })),
  ),
);

const ChargingElectricityUsageBySpecificSpotContainer = Loadable(
  lazy(() =>
    import(
      "../site-ev-chargers-electricity-usage/containers/electricity-usage-by-specific-spot"
    ).then(module => ({
      default: module.ElectricityUsageBySpecificSpotContainer,
    })),
  ),
);

const ChargingElectricityUsageBySpecificTenantContainer = Loadable(
  lazy(() =>
    import(
      "../site-ev-chargers-electricity-usage/containers/electricity-usage-by-specific-tenant"
    ).then(module => ({
      default: module.ElectricityUsageBySpecificTenantContainer,
    })),
  ),
);

const routes: DataRouteObject[] = [
  {
    path: "/",
    id: "statistics-redirect",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "/statistics",
        path: "/",
        element: <Navigate to="/statistics" replace />,
      },
    ],
  },
  {
    id: "statistics-container",
    path: "statistics",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "statistics",
        path: "/statistics",
        element: <Statistics />,
      },
    ],
  },
  {
    id: "tenants-container",
    path: "tenants",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "tenants-outlet",
        path: "/tenants",
        element: <TenantsContainer />,
        children: [
          {
            id: "tenants",
            index: true,
            element: <Tenants />,
          },
          {
            id: "tenants-invitations",
            path: "/tenants/invitations",
            element: <TenantsInvitations />,
          },
        ],
      },
      {
        id: "tenant-details",
        path: "/tenants/:tenantId",
        element: <Tenant />,
      },
      {
        id: "tenant-rule-group-details",
        path: "/tenants/:tenantId/rule-groups/:ruleGroupId",
        element: <TenantRuleGroup />,
      },
    ],
  },
  {
    id: "resource-container",
    path: "resource",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "resource",
        path: "/resource",
        element: <Resource />,
        children: [
          {
            id: "resource-spots",
            path: "/resource/spots",
            element: <Resources />,
          },
        ],
      },
    ],
  },
  {
    id: "blacklist-container",
    path: "blacklist",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "blacklist",
        path: "/blacklist",
        element: <BlackListLicensePlate />,
      },
    ],
  },
  {
    id: "settings-container",
    path: "settings",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "settings",
        path: "/settings",
        element: <Settings />,
        children: [
          {
            id: "settings-contact-form",
            index: true,
            element: <ContactForm />,
          },
          {
            id: "settings-change-password",
            path: "/settings/change-password",
            element: <ChangePassword />,
          },
          {
            id: "integration",
            path: "/settings/integration",
            element: <IntegrationQuestionnaireSitesContainer />,
          },
          {
            id: "integration-questionnaire",
            path: "/settings/integration-questionnaire",
            element: <IntegrationQuestionnaireFormContainer />,
          },
        ],
      },
      {
        id: "site-integration-questionnaire",
        path: "/settings/integration-questionnaire/:siteId",
        element: <SiteIntegrationQuestionnaire />,
      },
    ],
  },
  {
    id: "site-management-container",
    path: "site-management",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "site-management",
        path: "/site-management",
        element: <SiteManagementContainer />,
        children: [
          {
            id: "site-details",
            index: true,
            element: <SiteDetails />,
          },
          {
            id: "site-admins",
            path: "/site-management/admins",
            element: <SiteAdmins />,
          },
        ],
      },
      {
        id: "site-remove-status",
        path: "/site-management/remove/status",
        element: <SiteRemoveStatus />,
      },
    ],
  },
  {
    id: "ev-chargers-container",
    path: "ev-chargers",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "ev-chargers-tabs",
        path: "/ev-chargers",
        element: <ChargingSpotsTabContainer />,
        children: [
          {
            id: "ev-chargers",
            index: true,
            element: <ChargingSpotsContainer />,
          },
          {
            id: "ev-chargers-settings",
            path: "/ev-chargers/settings",
            element: <ChargingSettingsContainer />,
            children: [
              {
                id: "ev-chargers-usage-limits",
                index: true,
                element: <ChargingUsageLimitsContainer />,
              },
              {
                id: "ev-chargers-rules",
                path: "/ev-chargers/settings/rules",
                element: <ChargingRulesContainer />,
              },
            ],
          },
          {
            id: "ev-chargers-electricity-usage",
            path: "/ev-chargers/electricity-usage",
            element: <ChargingElectricityUsageContainer />,
          },
        ],
      },
      {
        id: "ev-chargers-electricity-usage-by-specific-spot",
        path: "/ev-chargers/electricity-usage/spot/:spotToken",
        element: <ChargingElectricityUsageBySpecificSpotContainer />,
      },
      {
        id: "ev-chargers-electricity-usage-by-specific-tenant",
        path: "/ev-chargers/electricity-usage/tenant/:tenantToken",
        element: <ChargingElectricityUsageBySpecificTenantContainer />,
      },
    ],
  },
  //? TODO: disable billing data fetching because Salesforce throws errors because it is not paid
  // {
  //   id: "billing-container",
  //   path: "billing",
  //   element: (
  //     <AuthGuard>
  //       <Layout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       id: "billing",
  //       path: "/billing",
  //       element: <Billing />,
  //       children: [
  //         {
  //           id: "all-sites-billing",
  //           index: true,
  //           element: <AllSitesBilling />,
  //         },
  //         // {
  //         //   id: "billing-history",
  //         //   path: "/billing/history",
  //         //   element: <BillingHistory />,
  //         // },
  //         {
  //           id: "billing-details",
  //           path: "/billing/details",
  //           element: <BillingDetails />,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default routes;
