import { useTranslation } from "react-i18next";
import { formatPrice } from "src/utils/format-price";

interface Props {
  amount: number;
  currency?: string;
}

export const PriceFormatter = ({ amount, currency = "EUR" }: Props) => {
  const { i18n } = useTranslation();

  const formattedAmount = formatPrice({ amount, currency, language: i18n.language });

  return <>{formattedAmount}</>;
};
