export const BookingRulesIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.6 14.7L8.4 16.5L12.45 12.45M8.4 8.4V5.88C8.4 4.87191 8.4 4.36786 8.59619 3.98282C8.76876 3.64413 9.04413 3.36876 9.38282 3.19619C9.76786 3 10.2719 3 11.28 3H18.12C19.1281 3 19.6321 3 20.0172 3.19619C20.3559 3.36876 20.6312 3.64413 20.8038 3.98282C21 4.36786 21 4.87191 21 5.88V12.72C21 13.7281 21 14.2321 20.8038 14.6172C20.6312 14.9559 20.3559 15.2312 20.0172 15.4038C19.6321 15.6 19.1281 15.6 18.12 15.6H15.6M5.88 21H12.72C13.7281 21 14.2321 21 14.6172 20.8038C14.9559 20.6312 15.2312 20.3559 15.4038 20.0172C15.6 19.6321 15.6 19.1281 15.6 18.12V11.28C15.6 10.2719 15.6 9.76786 15.4038 9.38282C15.2312 9.04413 14.9559 8.76876 14.6172 8.59619C14.2321 8.4 13.7281 8.4 12.72 8.4H5.88C4.87191 8.4 4.36786 8.4 3.98282 8.59619C3.64413 8.76876 3.36876 9.04413 3.19619 9.38282C3 9.76786 3 10.2719 3 11.28V18.12C3 19.1281 3 19.6321 3.19619 20.0172C3.36876 20.3559 3.64413 20.6312 3.98282 20.8038C4.36786 21 4.87191 21 5.88 21Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
