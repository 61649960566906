import { DialogContent } from "@material-ui/core";
import { t } from "i18next";
import { OnboardingDialogWrapper } from "src/components/organisms/onboarding/onboarding-dialog-wrapper";

interface Props {
  onNextStep: () => Promise<void>;
  onPreviousStep: () => void;
  currentStep: number;
  stepsLength: number;
}

export const AccessControlIntegrationStep = ({
  currentStep,
  onNextStep,
  stepsLength,
  onPreviousStep,
}: Props) => {
  return (
    <>
      <OnboardingDialogWrapper.Logo />
      <DialogContent sx={{ px: 4 }}>
        <OnboardingDialogWrapper.Description
          sx={{ width: "251px" }}
          text={t("onboarding:accessControlIntegration.description")}
        />
        <OnboardingDialogWrapper.Title text={t("onboarding:accessControlIntegration.title")} />
        <OnboardingDialogWrapper.AccessControlIntegration />
        <OnboardingDialogWrapper.Annotation
          text={t("onboarding:accessControlIntegration.annotation")}
        />
        <OnboardingDialogWrapper.StepIndicator currentStep={currentStep} totalSteps={stepsLength} />
      </DialogContent>
      <OnboardingDialogWrapper.Actions onNextStep={onNextStep} onPreviousStep={onPreviousStep} />
    </>
  );
};
