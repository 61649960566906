import { Box, Typography } from "@material-ui/core";
import { FC, ReactNode } from "react";

interface Props {
  title: string;
  trailingContentContainer?: ReactNode;
}

const LaunchErrorOverlay: FC<Props> = ({ title, trailingContentContainer }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        flexDirection: "column",
        gap: 1,
      }}>
      <Typography>{title}</Typography>
      {trailingContentContainer}
    </Box>
  );
};

export default LaunchErrorOverlay;
