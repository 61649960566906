import { Typography } from "@material-ui/core";
import { lightText } from "src/colors";

interface Props {
  text: string;
}

export const OnboardingDialogAnnotation = ({ text }: Props) => {
  return (
    <Typography
      color={lightText.secondary}
      sx={{ fontSize: "12px", lineHeight: "18px", fontWeight: "400" }}>
      {text}
    </Typography>
  );
};
