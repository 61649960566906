import { Box, Button, Link, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { supportEmail } from "src/constants/support";
import { logout } from "../../../api/services/supabase/auth";

const AdminOrganizationsErrorContent = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography>{t("siteManagement:siteAdmins.launchError.dueToTechnicalIssues")}</Typography>
      <Typography sx={{ mb: 2, textAlign: "center" }}>
        {t("siteManagement:siteAdmins.launchError.pleaseTryAgainLater")}
      </Typography>
      <Typography sx={{ mb: 3, textAlign: "center" }}>
        {t("siteManagement:siteAdmins.launchError.problemPersist")}
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
        <Button variant="outlined" onClick={logout}>
          {t("common:logout")}
        </Button>
        <Button variant="contained" onClick={() => window.location.reload()}>
          {t("siteManagement:siteAdmins.launchError.retry")}
        </Button>
      </Box>
    </Box>
  );
};

export default AdminOrganizationsErrorContent;
