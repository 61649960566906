import { makeAutoObservable, runInAction } from "mobx";
import { groupsApi } from "src/api/organization/groups";
import { Group } from "src/types/responses/group";

class GroupsStore {
  constructor() {
    makeAutoObservable(this);
  }

  groups: Group[] = null;

  fetchGroups = async () => {
    const groups = await groupsApi.fetchGroups(100, 1);

    runInAction(() => {
      this.groups = groups.items;
    });
  };
}

export default GroupsStore;
