export const CheckedIcon = () => {
  return (
    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.83341 0.912598L3.87508 6.87093L1.16675 4.1626"
        stroke="#6EBAD3"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
