import { Box } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputField } from "src/components/atoms/input-field";
import { OnboardingFormValues } from "./utils";

export const OnboardingDialogBuilding = () => {
  const { t } = useTranslation();

  const { control } = useFormContext<OnboardingFormValues>();

  return (
    <Box>
      <Controller
        name="building.siteName"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
          <InputField
            label={t("onboarding:building.form.buildingName")}
            fullWidth
            sx={{ width: "100%", mb: 1 }}
            required
            ref={ref}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            error={!!error}
            helperText={error?.message}
            labelSize="small"
          />
        )}
      />
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}>
        <Controller
          name="building.streetName"
          control={control}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
            <InputField
              label={t("onboarding:building.form.street")}
              fullWidth
              sx={{ mb: 1, width: "202px" }}
              ref={ref}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              error={!!error}
              helperText={error?.message}
              labelSize="small"
            />
          )}
        />
        <Controller
          name="building.buildingNumber"
          control={control}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
            <InputField
              label={t("onboarding:building.form.buildingNumber")}
              fullWidth
              sx={{ mb: 1, width: "124px" }}
              ref={ref}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              error={!!error}
              helperText={error?.message}
              labelSize="small"
            />
          )}
        />
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Controller
          name="building.cityName"
          control={control}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
            <InputField
              label={t("onboarding:building.form.city")}
              fullWidth
              sx={{ mb: 1, width: "202px" }}
              ref={ref}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              error={!!error}
              helperText={error?.message}
              labelSize="small"
            />
          )}
        />
        <Controller
          name="building.postcode"
          control={control}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
            <InputField
              label={t("onboarding:building.form.postcode")}
              fullWidth
              sx={{ mb: 1, width: "124px" }}
              ref={ref}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              error={!!error}
              helperText={error?.message}
              labelSize="small"
            />
          )}
        />
      </Box>
    </Box>
  );
};
