import { DialogContent } from "@material-ui/core";
import { t } from "i18next";
import { OnboardingDialogWrapper } from "src/components/organisms/onboarding/onboarding-dialog-wrapper";

interface Props {
  onNextStep: () => Promise<void>;
  currentStep: number;
  onPreviousStep: () => void;
  stepsLength: number;
}

export const NumberOfSpotsStep = ({
  currentStep,
  onNextStep,
  onPreviousStep,
  stepsLength,
}: Props) => {
  return (
    <>
      <OnboardingDialogWrapper.Logo />
      <DialogContent sx={{ px: 4 }}>
        <OnboardingDialogWrapper.Title text={t("onboarding:spots.title")} />
        <OnboardingDialogWrapper.Spots />
        <OnboardingDialogWrapper.StepIndicator currentStep={currentStep} totalSteps={stepsLength} />
      </DialogContent>
      <OnboardingDialogWrapper.Actions onPreviousStep={onPreviousStep} onNextStep={onNextStep} />
    </>
  );
};
