import axios from "axios";

import { attachAcceptLanguage } from "src/api/interceptors/attach-accept-language";
import { attachAccessToken } from "src/api/interceptors/attach-access-token";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { refreshAccessTokenLogic } from "src/api/interceptors/refresh-access-token-logic";
import { attachSiteToken } from "src/features/site/api/interceptors/attach-site-token";

const siteApiV1 = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/site-admin-api/v1`,
});

createAuthRefreshInterceptor(siteApiV1, refreshAccessTokenLogic);

siteApiV1.interceptors.request.use(attachAccessToken);
siteApiV1.interceptors.request.use(attachSiteToken);
siteApiV1.interceptors.request.use(attachAcceptLanguage);

export default siteApiV1;
