export const BlacklistedPlatesIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8 12C18.8 15.7555 15.7555 18.8 12 18.8C8.24446 18.8 5.2 15.7555 5.2 12C5.2 8.24446 8.24446 5.2 12 5.2C15.7555 5.2 18.8 8.24446 18.8 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM8 11.4C7.66863 11.4 7.4 11.6686 7.4 12C7.4 12.3314 7.66863 12.6 8 12.6H16C16.3314 12.6 16.6 12.3314 16.6 12C16.6 11.6686 16.3314 11.4 16 11.4H8Z"
        fill="currentColor"
      />
    </svg>
  );
};
