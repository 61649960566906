import { DialogTitle } from "@material-ui/core";
import { ParkizaLogo } from "src/icons/parkiza-logo";

export const OnboardingDialogLogo = () => {
  return (
    <DialogTitle
      sx={{
        display: "flex",
        justifyContent: "center",
        pt: 6.25,
        mb: 0,
        pb: 0,
      }}>
      <ParkizaLogo />
    </DialogTitle>
  );
};
