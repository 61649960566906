import { useState } from "react";
import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AppBar, Box, Button, Divider, IconButton, Toolbar, Tooltip } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { LanguagePopover } from "../molecules/language-popover";
import { AccountPopover } from "./account-popover";
import { NavbarMenu } from "./navbar-menu";

import type { Theme } from "@material-ui/core";

import { useSettings } from "../../contexts/settings-context";

import { ChevronDown as ChevronDownIcon } from "../../icons/chevron-down";
import { Moon as MoonIcon } from "../../icons/moon";
import { Sun as SunIcon } from "../../icons/sun";

import useStore from "src/hooks/use-store";
import { PanelContextSelector } from "./panel-context-selector";
import { Question } from "src/icons/question";

export const Navbar: FC = () => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { i18n, t } = useTranslation();

  const { settings, saveSettings } = useSettings();
  const [openMenu, setOpenMenu] = useState(false);
  const [darkMode, setDarkMode] = useState(settings.theme === "dark");

  const store = useStore();
  const theme = useTheme();
  const { mode } = theme.palette;

  const infoApp = {
    icon: mode === "dark" ? "/static/parkiza-light.png" : "/static/parkiza-dark.png",
    name: "Parkiza",
  };

  const handleLanguageChange = (language: "en" | "pl"): void => {
    i18n.changeLanguage(language);
    saveSettings({
      ...settings,
      language,
    });
    toast.success(t("common:languageChanged"));
  };

  const handleSwitchTheme = (): void => {
    saveSettings({
      ...settings,
      theme: settings.theme === "light" ? "dark" : "light",
    });

    setDarkMode(settings.theme === "light");
  };

  const helpjuiceUrl = () => {
    switch (store.user.viewContext) {
      case "site":
        switch (i18n.language) {
          case "en":
            return "https://signalosio.helpjuice.com/en_US/site-admin-panel";
          case "pl":
            return "https://signalosio.helpjuice.com/pl_PL/administrator-budynku";
        }
        break;
      case "tenant":
        switch (i18n.language) {
          case "en":
            return "https://signalosio.helpjuice.com/en_US/admin-panel-";
          case "pl":
            return "https://signalosio.helpjuice.com/pl_PL/panel-administratora-";
        }
    }
  };

  return (
    <AppBar elevation={0} sx={{ backgroundColor: "primary.contrastText", zIndex: "999" }}>
      <Toolbar
        disableGutters
        sx={{
          alignItems: "center",
          display: "flex",
          minHeight: 64,

          pr: 2,
          py: 1,
        }}>
        <Box
          component={RouterLink}
          to="/"
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            width: "72px",
            transition: "0.3s",
            position: "relative",
            "&:hover .apps": {
              visibility: "visible",
              opacity: 1,
            },
          }}>
          <Box
            className="logo"
            sx={{ transition: "0.3s", height: "100%", display: "flex", alignItems: "center" }}>
            <img src={infoApp.icon} alt="logo" style={{ width: "29.2px" }} />
          </Box>
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            mr: 3,
          }}
        />
        <Box sx={{ color: "text.primary" }}>{infoApp.name}</Box>
        <NavbarMenu onClose={() => setOpenMenu(false)} open={mdDown && openMenu} />
        <Button
          endIcon={
            <ChevronDownIcon
              fontSize="small"
              sx={{
                transition: "transform 250ms",
                transform: openMenu ? "rotate(180deg)" : "none",
              }}
            />
          }
          onClick={() => setOpenMenu(true)}
          sx={{
            color: "primary.text",
            display: {
              md: "none",
              xs: "flex",
            },
            fontSize: "14px",
            ml: 2,
          }}
          variant="text">
          Menu
        </Button>
        <Box sx={{ flexGrow: 1 }} />

        <PanelContextSelector />

        <LanguagePopover
          language={i18n.language}
          onLanguageChange={handleLanguageChange}
          sx={{
            mx: 1,
            display: "inline-flex",
          }}
        />
        <IconButton
          color="inherit"
          onClick={handleSwitchTheme}
          sx={{
            mx: 1,
            display: "inline-flex",
          }}>
          {mode === "dark" ? (
            <SunIcon color="primary" sx={{ width: 20 }} />
          ) : (
            <MoonIcon color="primary" sx={{ width: 20 }} />
          )}
        </IconButton>
        <Tooltip title={t("common:help")}>
          <IconButton
            color="inherit"
            onClick={() => window.open(helpjuiceUrl(), "_blank")}
            sx={{
              display: "inline-flex",
              alignItems: "center",
            }}>
            <Question color="primary" sx={{ width: 20 }} />
          </IconButton>
        </Tooltip>
        <AccountPopover darkMode={darkMode} />
      </Toolbar>
      <Divider />
    </AppBar>
  );
};
