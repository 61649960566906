import { Box, Divider, Typography } from "@material-ui/core";
import { intervalToDuration, isAfter } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { lightPrimary } from "src/colors";
import { timeRemaining } from "../../../utils/time-remaining";

interface Props {
  date: Date;
}

const timeLeftPlaceholder = {
  days: "--",
  hours: "--",
  minutes: "--",
  seconds: "--",
};
const timeEndedPlaceholder = {
  days: "00",
  hours: "00",
  minutes: "00",
  seconds: "00",
};

const Countdown = ({ date }: Props) => {
  const { t } = useTranslation();

  const targetDate = date;

  const [timeLeft, setTimeLeft] = useState<{
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
  }>(() => timeLeftPlaceholder);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    interval = setInterval(() => {
      const hasTimePassed = isAfter(new Date(), targetDate);

      if (hasTimePassed) {
        setTimeLeft(timeEndedPlaceholder);

        if (interval) {
          clearInterval(interval);
        }
      } else {
        const duration = intervalToDuration({
          start: new Date(),
          end: targetDate,
        });

        setTimeLeft(timeRemaining(duration));
      }
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [targetDate]);

  return (
    <Box sx={{ display: "flex", gap: 3 }}>
      <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center", width: "140px" }}>
        <Typography sx={{ fontSize: "45px", color: lightPrimary.hoverOutlined }}>
          {timeLeft.days}
        </Typography>
        <Typography color="text.secondary" sx={{ fontSize: "20px" }}>
          {t("common:maintenanceBrake.days", { count: Number(timeLeft.days) })}
        </Typography>
      </Box>
      <Divider orientation="vertical" sx={{ borderColor: lightPrimary.hoverOutlined }} />
      <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center", width: "140px" }}>
        <Typography sx={{ fontSize: "45px", color: lightPrimary.hoverOutlined }}>
          {timeLeft.hours}
        </Typography>
        <Typography color="text.secondary" sx={{ fontSize: "20px" }}>
          {t("common:maintenanceBrake.hours", { count: Number(timeLeft.hours) })}
        </Typography>
      </Box>
      <Divider orientation="vertical" sx={{ borderColor: lightPrimary.hoverOutlined }} />
      <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center", width: "140px" }}>
        <Typography sx={{ fontSize: "45px", color: lightPrimary.hoverOutlined }}>
          {timeLeft.minutes}
        </Typography>
        <Typography color="text.secondary" sx={{ fontSize: "20px" }}>
          {t("common:maintenanceBrake.minutes", { count: Number(timeLeft.minutes) })}
        </Typography>
      </Box>
      <Divider orientation="vertical" sx={{ borderColor: lightPrimary.hoverOutlined }} />
      <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center", width: "140px" }}>
        <Typography sx={{ fontSize: "45px", color: lightPrimary.hoverOutlined }}>
          {timeLeft.seconds}
        </Typography>
        <Typography color="text.secondary" sx={{ fontSize: "20px" }}>
          {t("common:maintenanceBrake.seconds", { count: Number(timeLeft.seconds) })}
        </Typography>
      </Box>
    </Box>
  );
};

export default Countdown;
