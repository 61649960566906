import type { FC, ElementType } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Typography, useTheme } from "@material-ui/core";
import { ExternalLink as ExternalLinkIcon } from "../../../icons/external-link";
import { RouteTitle } from "src/types/enum/route-title";
import { darkPrimary, lightPrimary } from "src/colors";

interface SidebarItemProps {
  active: boolean;
  external?: boolean;
  href: string;
  icon: ElementType;
  pinned: boolean;
  title: RouteTitle;
  id?: string;
}

export const SidebarItem: FC<SidebarItemProps> = ({
  active,
  external,
  href,
  icon: Icon,
  pinned,
  title,
  id,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <li>
      <Button
        component={RouterLink}
        endIcon={external && <ExternalLinkIcon sx={{ color: "action.disabled" }} />}
        fullWidth
        id={id}
        startIcon={<Icon />}
        target={external ? "_target" : "_self"}
        sx={{
          justifyContent: "flex-start",
          lineHeight: 0,
          minWidth: "fit-content",
          fontSize: "13px",
          px: 1.375,
          py: 1.5,
          "& .MuiButton-startIcon": {
            color: active ? (mode === "dark" ? darkPrimary.main : "#000") : "text.secondary",
            margin: 0,
          },
          "& .MuiButton-endIcon": {
            color: "action.disabled",
            display: pinned ? "flex" : "none",
            marginLeft: "auto",
          },
          background: active
            ? `${mode === "dark" ? darkPrimary.hoverOutlined : lightPrimary.hoverOutlined}14`
            : "transparent",
          ":focus": {
            boxShadow: "none",
            background: {
              md: active
                ? `${mode === "dark" ? darkPrimary.hoverOutlined : lightPrimary.hoverOutlined}14`
                : "transparent",
            },
          },
        }}
        to={href}
        variant="text">
        <Typography
          color="textPrimary"
          sx={{
            color: active ? "primary" : "text.primary",
            display: pinned ? "flex" : "none",
            ml: 1.62,
          }}
          variant="inherit">
          {t(`${title}` as const)}
        </Typography>
      </Button>
    </li>
  );
};
