import { Box, Button, Container, Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import Countdown from "src/components/atoms/countdown/countdown";
import { formatDate } from "src/components/atoms/format-date";
import { WorkInProgress } from "src/icons/work-in-progress";
import { dateFormats } from "src/utils/date-formats";

interface Props {
  estimatedMaintenanceCompletionDate: Date | null;
}

export const MaintenanceBrake = ({ estimatedMaintenanceCompletionDate }: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ backgroundColor: "background.default", height: "100%", width: "100%" }}>
      {/* <Navbar /> */}
      <Container
        maxWidth="md"
        sx={{
          px: 5,
          py: 14,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        }}>
        <WorkInProgress sx={{ width: "32px", height: "32px" }} />
        <Typography align="center" color="textPrimary" sx={{ my: 2 }} variant="h3">
          {estimatedMaintenanceCompletionDate ? (
            <Trans
              t={t}
              i18nKey={"common:maintenanceBrake.title"}
              values={{
                date: formatDate(
                  new Date(estimatedMaintenanceCompletionDate),
                  `${dateFormats.day} ${dateFormats.monthShort} ${dateFormats.year}, ${dateFormats.hours}:${dateFormats.minutes}`,
                ),
              }}
              shouldUnescape
            />
          ) : (
            t("common:maintenanceBrake.noDateFallback")
          )}
        </Typography>
        {estimatedMaintenanceCompletionDate ? (
          <>
            <Typography align="center" color="textSecondary" variant="body2" sx={{ mb: 2 }}>
              {t("common:maintenanceBrake.message")}
            </Typography>
            <Countdown date={new Date(estimatedMaintenanceCompletionDate)} />
          </>
        ) : null}
        <Button
          color="primary"
          sx={{ mt: 4 }}
          onClick={() => window.location.reload()}
          variant="outlinedContained">
          {t("common:maintenanceBrake.refreshPage")}
        </Button>
      </Container>
    </Box>
  );
};
