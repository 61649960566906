import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { t } from "i18next";
import { Controller, useFormContext } from "react-hook-form";

import PhoneNumberInput from "src/components/atoms/phone-number-input/phone-number-input";
import { lightText } from "src/colors";
import { FEE_PER_SINGLE_SPOT } from "src/utils/calculate-monthly-fee";
import { formatPrice } from "src/utils/format-price";
import { useTranslation } from "react-i18next";
import { OnboardingFormValues } from "./utils";

export const OnboardingDialogAccessControlIntegration = () => {
  const { control, watch } = useFormContext<OnboardingFormValues>();
  const { i18n } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        mb: 3,
      }}>
      <FormControl>
        <Controller
          name="accessControlIntegration"
          control={control}
          defaultValue="no"
          render={({ field }) => (
            <RadioGroup {...field}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                }}>
                <FormControlLabel
                  value="yes"
                  sx={{
                    span: {
                      color: "#000",
                    },
                  }}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "black",
                        },
                      }}
                    />
                  }
                  label={t("onboarding:accessControlIntegration.yes")}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  sx={{
                    span: {
                      color: "#000",
                    },
                  }}
                  label={t("onboarding:accessControlIntegration.no")}
                />
              </Box>
            </RadioGroup>
          )}
        />
      </FormControl>
      <Typography color={lightText.secondary} sx={{ fontSize: "12px", my: 3 }}>
        {t("onboarding:accessControlIntegration.additionalInformation", {
          spotPrice: formatPrice({ amount: FEE_PER_SINGLE_SPOT, language: i18n.language }),
        })}
      </Typography>
      {watch("accessControlIntegration") === "yes" ? (
        <>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <PhoneNumberInput
                label={t("onboarding:accessControlIntegration.phoneNumber")}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Typography
            color={lightText.secondary}
            sx={{ fontSize: "12px", fontWeight: "400", py: 0.5 }}>
            {t("onboarding:accessControlIntegration.contact")}
          </Typography>
        </>
      ) : null}
    </Box>
  );
};
