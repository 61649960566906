import { ComponentType, ReactNode, createContext } from "react";
import { createContextualCan, useAbility } from "@casl/react";
import { AppAbility, definePermissions } from "./define-permissions";
import { UserRole } from "src/types/enum/user-role";
import NotSufficientPermissions from "src/components/atoms/not-sufficient-permissions";
import { ViewContext } from "src/store/user-store";
import { SubscriptionPlan } from "src/types/interfaces/subscription-plan";

export const AbilityContext = createContext<AppAbility>(null);

const rolesMap = {
  [UserRole.OWNER]: "owner",
  [UserRole.ADMIN]: "admin",
  [UserRole.MASTER]: "master",
  [UserRole.VIEWER]: "readonly",
} as const;

export const AbilityProvider = ({
  children,
  role,
  userId,
  viewContext,
  organizationPlan,
  shouldHaveAccessToSiteActionsInTenantContext,
  isLegacyAgreement,
  hasAccessControlIntegration,
  siteHasAccessToEVChargers,
  tenantHasAccessToEVChargers,
}: {
  children: ReactNode;
  role: UserRole;
  userId: number;
  viewContext: ViewContext;
  sitePlan?: SubscriptionPlan;
  organizationPlan?: SubscriptionPlan;
  shouldHaveAccessToSiteActionsInTenantContext: boolean;
  isLegacyAgreement: boolean;
  hasAccessControlIntegration: boolean;
  siteHasAccessToEVChargers: boolean;
  tenantHasAccessToEVChargers: boolean;
}) => {
  const ability = definePermissions(
    viewContext,
    rolesMap[role],
    userId,
    shouldHaveAccessToSiteActionsInTenantContext,
    isLegacyAgreement,
    hasAccessControlIntegration,
    siteHasAccessToEVChargers,
    tenantHasAccessToEVChargers,
    organizationPlan,
  );

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export const Can = createContextualCan(AbilityContext.Consumer);

export const usePermissions = () => {
  const ability = useAbility(AbilityContext);

  if (!ability) {
    throw new Error("usePermissions must be used within a AbilityProvider.");
  }

  return ability;
};

export const withPermissions = <T,>(
  shouldShowNotSufficientPermissions: (permissions: AppAbility) => boolean,
  Component: ComponentType<T>,
) => {
  return (props: T) => {
    const permissions = usePermissions();

    const showNotSufficientPermissions = shouldShowNotSufficientPermissions(permissions);

    if (showNotSufficientPermissions) {
      return <NotSufficientPermissions />;
    }

    return <Component {...props} />;
  };
};
