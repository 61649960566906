import { InternalAxiosRequestConfig } from "axios";
import store from "src/store/application-store";

export const attachSiteToken = (config: InternalAxiosRequestConfig) => {
  const { user } = store;

  if (user.siteToken) {
    config.headers["s-site-token"] = user.siteToken;
  }

  return config;
};
