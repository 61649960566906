import type { FC, ElementType } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ListItemButton, ListItemIcon, ListItemText, useTheme } from "@material-ui/core";
import { ExternalLink as ExternalLinkIcon } from "../../../icons/external-link";
import { RouteTitle } from "src/types/enum/route-title";
import { darkPrimary, lightPrimary } from "src/colors";

interface NavbarMenuItemProps {
  active: boolean;
  external?: boolean;
  href: string;
  icon: ElementType;
  onClose: () => void;
  title: RouteTitle;
}

export const NavbarMenuItem: FC<NavbarMenuItemProps> = ({
  active,
  external,
  href,
  icon: Icon,
  onClose,
  title,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <ListItemButton
      component={RouterLink}
      divider
      onClick={onClose}
      sx={{
        alignItems: "center",
        borderColor: "#3F455A",
        display: "flex",
        backgroundColor: "primary.contrastText",
        px: 3,
        py: 1.5,
        ...(active && {
          backgroundColor: `${
            mode === "dark" ? darkPrimary.hoverOutlined : lightPrimary.hoverOutlined
          }14`,
          color: "#ffffff",
        }),
        "&:hover": {
          backgroundColor: "primary.contrastText",
          color: "#ffffff",
        },
        color: "primary.main",
      }}
      target={external ? "_target" : "_self"}
      to={href}>
      <ListItemIcon
        sx={{
          color: active ? (mode === "dark" ? darkPrimary.main : "#000") : "text.secondary",
          minWidth: 0,
          mr: 1,
        }}>
        <Icon />
      </ListItemIcon>
      <ListItemText
        primary={t(title)}
        primaryTypographyProps={{
          color: "inherit",
          variant: "caption",
        }}
      />
      {external && <ExternalLinkIcon sx={{ color: "#506176" }} />}
    </ListItemButton>
  );
};
