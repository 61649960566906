import { createSvgIcon } from "@material-ui/core";

export const Question = createSvgIcon(
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="8.5" stroke="currentColor" />
    <path
      d="M7.54553 7.47953C9.1819 3.38854 15.7274 8.298 10.0001 10.7523"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <circle cx="9.68201" cy="12.9551" r="0.5" fill="currentColor" />
  </svg>,
  "Question",
);
