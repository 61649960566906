import { DialogContent } from "@material-ui/core";
import { t } from "i18next";
import { OnboardingFormValues } from "src/components/organisms/onboarding/utils";
import { OnboardingDialogWrapper } from "src/components/organisms/onboarding/onboarding-dialog-wrapper";

interface Props {
  onNextStep: () => Promise<void>;
  currentStep: number;
  onPreviousStep: () => void;
  stepsLength: number;
  onSubmit: (data: OnboardingFormValues) => Promise<void>;
  isFormSubmitting?: boolean;
}

export const NumberOfUsersStep = ({
  currentStep,
  onNextStep,
  onSubmit,
  onPreviousStep,
  stepsLength,
  isFormSubmitting,
}: Props) => {
  return (
    <>
      <OnboardingDialogWrapper.Logo />
      <DialogContent sx={{ px: 4 }}>
        <OnboardingDialogWrapper.Title text={t("onboarding:users.title")} />
        <OnboardingDialogWrapper.Users />
        <OnboardingDialogWrapper.StepIndicator currentStep={currentStep} totalSteps={stepsLength} />
      </DialogContent>
      <OnboardingDialogWrapper.Actions
        onPreviousStep={onPreviousStep}
        onNextStep={onNextStep}
        onSubmit={onSubmit}
        isFormSubmitting={isFormSubmitting}
      />
    </>
  );
};
