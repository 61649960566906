import siteApiV1 from "src/api/clients/site-api-v1";
import { SiteEvChargersModuleAccessResponse } from "../types/site-ev-chargers-module-access.response";

class siteEvChargersModuleAccess {
  async getAccess(): Promise<SiteEvChargersModuleAccessResponse> {
    const { data } = await siteApiV1.get<SiteEvChargersModuleAccessResponse>("/ev-chargers/site");

    return data;
  }
}

export const siteEvChargersModuleAccessApi = new siteEvChargersModuleAccess();
