import { useEffect } from "react";
import { AdminProfile } from "src/types/responses/admin-profile";
import { QueryDataState } from "src/types/responses/query-data-state";
import { useAdminsProfile } from "../features/organization/hooks/api/admins/use-admins-profile";
import useStore from "./use-store";

type ReturnType = [QueryDataState<AdminProfile>, () => void];

export const useAdminOrganizations = (enabled?: boolean): ReturnType => {
  const { user } = useStore();

  const { data, isLoading, error } = useAdminsProfile(enabled, false);

  const handleClearOrganizationToken = () => {
    const savedOrganization = localStorage.getItem("userOrganizationId");

    const hasAccessToSavedOrganization = data?.organizations?.some(
      organization => organization.token === savedOrganization,
    );

    if (!hasAccessToSavedOrganization) {
      user.clearUserOrganizationId();
      return;
    }
  };

  useEffect(() => {
    if (data?.organizations?.length > 0) {
      handleClearOrganizationToken();
    }
  }, [data]);

  return [
    {
      data,
      isLoading,
      error,
    },
    handleClearOrganizationToken,
  ];
};
