import { Box, Typography } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputField } from "src/components/atoms/input-field";
import { OnboardingFormValues } from "./utils";
import { lightPrimary, lightText } from "src/colors";
import { FEE_PER_SINGLE_SPOT } from "src/utils/calculate-monthly-fee";
import { PriceFormatter } from "src/components/atoms/price-formatter";

export const OnboardingDialogSpots = () => {
  const { t } = useTranslation();

  const { control } = useFormContext<OnboardingFormValues>();

  return (
    <Box sx={{ width: "100%" }}>
      <Controller
        name="numberOfSpots"
        control={control}
        defaultValue={0}
        rules={{ required: true, min: 0 }}
        render={({ field: { onChange, value, onBlur, ref, name }, fieldState: { error } }) => (
          <>
            <InputField
              label={t("onboarding:spots.label")}
              name={name}
              value={value}
              onChange={onChange}
              type="number"
              min={0}
              error={!!error}
              helperText={error?.message}
              ref={ref}
              onBlur={onBlur}
              fullWidth
            />
            <Box sx={{ display: "flex", gap: 0.5, mt: 1, mb: 0.5 }}>
              <Typography color="black" variant="inherit" sx={{ fontSize: "12px" }}>
                <b>{t("onboarding:users.monthlyFee")}</b>
              </Typography>
              <Typography
                color={lightPrimary.hoverOutlined}
                variant="inherit"
                sx={{ fontSize: "12px" }}>
                <b>
                  <PriceFormatter amount={value * FEE_PER_SINGLE_SPOT} currency="EUR" />*
                </b>
              </Typography>
            </Box>
            <Typography
              color={lightText.secondary}
              variant="body2"
              sx={{ fontSize: "12px", my: 3 }}>
              {t("onboarding:spots.monthlyFreeAnnotation.part1")}
              <b>{t("onboarding:spots.monthlyFreeAnnotation.part2")}</b>
              {t("onboarding:spots.monthlyFreeAnnotation.part3")}
            </Typography>
            <Typography color={lightText.secondary} variant="body2" sx={{ fontSize: "12px" }}>
              {t("onboarding:spots.monthlyFeeDescription")}
            </Typography>
          </>
        )}
      />
    </Box>
  );
};
