export const timeRemaining = (
  duration: Duration,
): { days: string; hours: string; minutes: string; seconds: string } => {
  const days = (duration.days ?? 0) < 10 ? `0${duration.days}` : String(duration.days);
  const hours = (duration.hours ?? 0) < 10 ? `0${duration.hours}` : String(duration.hours);
  const minutes = (duration.minutes ?? 0) < 10 ? `0${duration.minutes}` : String(duration.minutes);
  const seconds = (duration.seconds ?? 0) < 10 ? `0${duration.seconds}` : String(duration.seconds);

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};
