export enum UserRole {
  ADMIN = "admin",
  OWNER = "owner",
  VIEWER = "viewer",
  MASTER = "master",
}

export enum UserRoleTranslations {
  ADMIN = "common:roles.admin",
  OWNER = "common:roles.owner",
  VIEWER = "common:roles.viewer",
  MASTER = "common:roles.master",
}
