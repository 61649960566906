import axios from "axios";
import { attachAcceptLanguage } from "../interceptors/attach-accept-language";

import { attachAccessToken } from "../interceptors/attach-access-token";
import { attachOrganizationToken } from "../interceptors/attach-organization-token";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { refreshAccessTokenLogic } from "../interceptors/refresh-access-token-logic";

const api = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/organization-admin-api/v3/parking-spots`,
});

createAuthRefreshInterceptor(api, refreshAccessTokenLogic);

api.interceptors.request.use(attachAccessToken);
api.interceptors.request.use(attachOrganizationToken);
api.interceptors.request.use(attachAcceptLanguage);

export default api;
