import { createSvgIcon } from "@material-ui/core/utils";

export const Sun = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5247_191765)">
      <path
        d="M10 1V4M10 16V19M19 10H16M4 10H1M16.364 16.364L14.5 14.5M5.5 5.5L3.636 3.636M16.364 3.636L14.5 5.5M5.5 14.5L3.636 16.364M14 10C14 11.0609 13.5786 12.0783 12.8284 12.8284C12.0783 13.5786 11.0609 14 10 14C8.93913 14 7.92172 13.5786 7.17157 12.8284C6.42143 12.0783 6 11.0609 6 10C6 8.93913 6.42143 7.92172 7.17157 7.17157C7.92172 6.42143 8.93913 6 10 6C11.0609 6 12.0783 6.42143 12.8284 7.17157C13.5786 7.92172 14 8.93913 14 10Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5247_191765">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "Sun",
);
