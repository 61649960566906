import { BookingRulesIcon } from "src/icons/navigation-icons/booking-rules-icon";
import { ChargingSpotsIcon } from "src/icons/navigation-icons/charging-spots-icon";
import { EmployeeIcon } from "src/icons/navigation-icons/employees-icon";
import { IssuesIcon } from "src/icons/navigation-icons/issues-icon";
import { OrganizationDetailsIcon } from "src/icons/navigation-icons/organization-details-icon";
import { ReservationsIcon } from "src/icons/navigation-icons/reservations-icon";
import { SettingsIcon } from "src/icons/navigation-icons/settings-icon";
import { SitesDetailsIcon } from "src/icons/navigation-icons/sites-details-icon";
import { SpotsIcon } from "src/icons/navigation-icons/spots-icon";
import { StatisticIcon } from "src/icons/navigation-icons/statistic-icon";
import { RouteTitle, SidebarRouteTitle } from "src/types/enum/route-title";
import { RoutesGroup } from "src/types/interfaces/routes-group";

export const getRoutes = (hasAccessToEVChargers: boolean): RoutesGroup[] => [
  {
    title: SidebarRouteTitle.PARKING_SETUP,
    routes: [
      {
        icon: BookingRulesIcon,
        title: RouteTitle.RULE_GROUPS,
        href: "/booking-rules",
        id: "booking_rules_navigation_link",
      },
      {
        icon: SpotsIcon,
        title: RouteTitle.PARKING_SPOTS,
        href: "/resource/spots",
        id: "parking_spots_navigation_link",
      },
      ...(hasAccessToEVChargers
        ? [
            {
              icon: ChargingSpotsIcon,
              title: RouteTitle.CHARGING_SPOTS,
              href: "/charging-spots",
              id: "charging_spots_navigation_link",
            },
          ]
        : []),
      {
        icon: EmployeeIcon,
        title: RouteTitle.EMPLOYEES,
        href: "/employees",
        id: "employees_navigation_link",
      },
    ],
  },
  {
    title: SidebarRouteTitle.DATA_AND_ISSUE,
    routes: [
      {
        icon: StatisticIcon,
        title: RouteTitle.STATISTICS,
        href: "/statistics",
        id: "statistics_navigation_link",
      },
      {
        icon: ReservationsIcon,
        title: RouteTitle.RESERVATIONS,
        href: "/reservations",
        id: "reservations_navigation_link",
      },
      {
        icon: IssuesIcon,
        title: RouteTitle.ISSUES,
        href: "/issues",
        id: "Issues_navigation_link",
      },
    ],
  },
  {
    title: SidebarRouteTitle.SUPPORT_AND_SETTINGS,
    routes: [
      {
        icon: OrganizationDetailsIcon,
        title: RouteTitle.ORGANIZATION_DETAILS,
        href: "/organization-details",
        id: "organization_details_navigation_link",
      },
      //? TODO: disable billing data fetching because Salesforce throws errors because it is not paid
      // ...(!isLegacyAgreement
      //   ? [
      //       {
      //         icon: BillingIcon,
      //         title: RouteTitle.BILLING,
      //         href: "/billing",
      //         id: "billing_navigation_link",
      //       },
      //     ]
      //   : []),
      {
        icon: SitesDetailsIcon,
        title: RouteTitle.SITES_DETAILS,
        href: "/sites-details",
        id: "sites_details_navigation_link",
      },
      {
        icon: SettingsIcon,
        title: RouteTitle.SETTINGS,
        href: "/settings",
        id: "settings_navigation_link",
      },
    ],
  },
];
