import { redirect } from "react-router-dom";
import supabase from "src/api/clients/supabase";

export const logout = async () => {
  const response = await supabase.auth.signOut();

  if (response.error) {
    throw response.error;
  }

  redirect("/login");
};

export const login = async (email: string, password: string) => {
  const response = await supabase.auth.signInWithPassword({ email, password });

  if (response.error) {
    throw response.error;
  }
};

export const updatePassword = async (password: string) => {
  const response = await supabase.auth.updateUser({ password });

  if (response.error) {
    throw response.error;
  }
};

export const refreshSession = async () => {
  const response = await supabase.auth.refreshSession();

  if (response.error) {
    throw response.error;
  }

  return response;
};
