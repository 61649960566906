import { DialogContent } from "@material-ui/core";
import { t } from "i18next";

import { OnboardingFormValues } from "src/components/organisms/onboarding/utils";
import { OnboardingDialogWrapper } from "src/components/organisms/onboarding/onboarding-dialog-wrapper";

interface Props {
  onSubmit: (data: OnboardingFormValues) => Promise<void>;
  currentStep: number;
  onPreviousStep: () => void;
  stepsLength: number;
  isFormSubmitting: boolean;
}

export const BuildingStep = ({
  currentStep,
  onPreviousStep,
  onSubmit,
  stepsLength,
  isFormSubmitting,
}: Props) => {
  return (
    <>
      <OnboardingDialogWrapper.Logo />
      <DialogContent sx={{ px: 4 }}>
        <OnboardingDialogWrapper.Title text={t("onboarding:building.title")} />
        <OnboardingDialogWrapper.Building />
        <OnboardingDialogWrapper.Annotation text={t("onboarding:building.annotation")} />
        <OnboardingDialogWrapper.StepIndicator currentStep={currentStep} totalSteps={stepsLength} />
      </DialogContent>
      <OnboardingDialogWrapper.Actions
        isFormSubmitting={isFormSubmitting}
        onSubmit={onSubmit}
        onPreviousStep={onPreviousStep}
      />
    </>
  );
};
