export const IssuesIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 9.78013V13.3349M12 16.8896H12.0088M10.7858 5.24048L3.57337 17.8657C3.17332 18.566 2.9733 18.9161 3.00286 19.2035C3.02865 19.4541 3.15823 19.6819 3.35935 19.8301C3.58994 20 3.98916 20 4.7876 20H19.2124C20.0108 20 20.4101 20 20.6406 19.8301C20.8418 19.6819 20.9714 19.4541 20.9971 19.2035C21.0267 18.9161 20.8267 18.566 20.4266 17.8657L13.2142 5.24048C12.8156 4.54271 12.6163 4.19383 12.3563 4.07666C12.1295 3.97445 11.8705 3.97445 11.6437 4.07666C11.3837 4.19383 11.1844 4.54272 10.7858 5.24048Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
