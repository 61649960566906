import { useQuery } from "@tanstack/react-query";
import { tenantEvChargersModuleAccessApi } from "../../api/lib/tenant-ev-chargers-module-access";
import { tenantEvChargersModuleAccessKeys } from "../../api/query-keys/tenant-ev-chargers-module-access";
import { useAuth } from "src/contexts/auth-provider";

export const useTenantEvChargersModuleAccess = () => {
  const { session } = useAuth();

  return useQuery({
    queryFn: tenantEvChargersModuleAccessApi.getAccess,
    queryKey: tenantEvChargersModuleAccessKeys.all,
    enabled: !!session,
  });
};
