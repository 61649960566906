import { InternalAxiosRequestConfig } from "axios";
import { authStoreOutsideComponent } from "src/contexts/auth-provider";

export const attachAccessToken = (config: InternalAxiosRequestConfig) => {
  const { session } = authStoreOutsideComponent();

  if (session) {
    config.headers["Authorization"] = `Bearer ${session.access_token}`;
  }

  return config;
};
