import { Box } from "@material-ui/core";
import type { ReactNode } from "react";
import { useApiSystemStatus } from "src/hooks/api/cms/use-api-system-status";
import Loader from "../atoms/loader/loader";
import { MaintenanceBrake } from "src/containers/maintenance-brake/maintenance-brake";
import { addMinutes } from "date-fns";

interface Props {
  children: ReactNode;
}

export const MaintenanceGuard = ({ children }: Props) => {
  const { data, isLoading, isFetched } = useApiSystemStatus();

  if (isLoading && !isFetched) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <Loader />
      </Box>
    );
  }

  const estimatedMaintenanceCompletionDate = data?.data?.attributes?.estimatedMaintenanceCompletion;

  let showMaintenance = data?.data?.attributes?.isMaintenance;

  if (showMaintenance) {
    const skipMaintenance = localStorage.getItem("skipMaintenance");

    if (skipMaintenance === "true") {
      showMaintenance = false;
    }
  }

  if (showMaintenance) {
    return (
      <MaintenanceBrake
        estimatedMaintenanceCompletionDate={
          estimatedMaintenanceCompletionDate
            ? new Date(estimatedMaintenanceCompletionDate)
            : addMinutes(new Date(), 30)
        }
      />
    );
  }

  return <>{children}</>;
};
