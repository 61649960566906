export const OrganizationDetailsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0719 20H4.92891V12.1538H3.79425C3.06267 12.1538 2.72131 11.226 3.27224 10.7362L10.7989 3.48696C11.4726 2.83768 12.5276 2.83768 13.2013 3.48696L20.7273 10.7362C21.2789 11.2253 20.9375 12.1538 20.2053 12.1538H19.0719V20Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 20H10V16.4C10 15.0746 10.8955 14 12 14C13.1045 14 14 15.0746 14 16.4V20Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
