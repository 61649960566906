import { forwardRef } from "react";
import type { ReactNode } from "react";
import SimpleBar, { Props } from "simplebar-react";

interface ScrollbarProps extends Props {
  children?: ReactNode;
}

export const Scrollbar = forwardRef<HTMLDivElement, ScrollbarProps>(
  ({ children, ...other }, ref) => {
    return (
      <SimpleBar
        // @ts-ignore
        ref={ref}
        {...other}>
        {children}
      </SimpleBar>
    );
  },
);
