import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ApiError } from "src/types/interfaces/api-error";
import { RTL } from "./rtl";
import { createCustomTheme } from "src/theme";
import { useSettings } from "src/contexts/settings-context";
import { LocalizationProvider } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-US";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { TechnicalBrake } from "src/containers/technical-brake/technical-brake";
import { MaintenanceGuard } from "./guards/maintenance-guard";
import { useUpdateLanguage } from "src/hooks/use-update-language";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: error => {
        const apiError = error as ApiError;

        if (apiError?.response?.status >= 500) {
          return true;
        }
        return false;
      },
      retry: false,
    },
  },
});

export const Providers = ({ children }: { children: ReactNode }) => {
  const { settings } = useSettings();

  const theme = createCustomTheme({
    direction: settings.direction,
    theme: settings.theme,
  });

  useUpdateLanguage();

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <LocalizationProvider
          locale={settings.language === "en" ? en : pl}
          dateAdapter={AdapterDateFns}>
          <ErrorBoundary FallbackComponent={TechnicalBrake}>
            <QueryClientProvider client={queryClient}>
              {/*
                //? should MaintenanceGuard be without the need of user to login
                //? have to think about that case
              */}
              <MaintenanceGuard>{children}</MaintenanceGuard>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </ErrorBoundary>
        </LocalizationProvider>
      </RTL>
    </ThemeProvider>
  );
};
