import { createContext } from "react";

import ApplicationStore from "../store/application-store";
import { IApplicationStoreContext } from "../types/application-store";

export const ApplicationStoreContext = createContext<IApplicationStoreContext>(null);

const ApplicationStoreProvider = ({ children }) => (
  <ApplicationStoreContext.Provider value={ApplicationStore}>
    {children}
  </ApplicationStoreContext.Provider>
);

export default ApplicationStoreProvider;
