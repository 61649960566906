export const EmployeeIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4438 15.4106C18.7626 16.0607 19.8932 17.104 20.7185 18.4086C20.8819 18.6669 20.9636 18.7961 20.9919 18.975C21.0493 19.3385 20.796 19.7854 20.451 19.9292C20.2812 20 20.0902 20 19.7083 20M15.6322 11.5842C16.9744 10.9297 17.8967 9.57054 17.8967 8C17.8967 6.42946 16.9744 5.07032 15.6322 4.41579M13.8207 8C13.8207 10.2091 11.9958 12 9.74462 12C7.49348 12 5.66857 10.2091 5.66857 8C5.66857 5.79086 7.49348 4 9.74462 4C11.9958 4 13.8207 5.79086 13.8207 8ZM3.45775 18.1674C4.90185 16.0396 7.18067 14.6667 9.74462 14.6667C12.3086 14.6667 14.5874 16.0396 16.0315 18.1674C16.3479 18.6336 16.506 18.8666 16.4878 19.1644C16.4736 19.3962 16.3188 19.68 16.13 19.8201C15.8876 20 15.5542 20 14.8874 20H4.60187C3.93504 20 3.60163 20 3.3592 19.8201C3.17045 19.68 3.01559 19.3962 3.00141 19.1644C2.9832 18.8666 3.14138 18.6336 3.45775 18.1674Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
