import { createSvgIcon } from "@material-ui/core/utils";

export const Moon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
    <path
      d="M13.2414 13.23C14.8356 13.5557 16.4904 13.4057 18 12.7986C17.382 14.3365 16.318 15.6542 14.9448 16.5822C13.5716 17.5102 11.9522 18.0061 10.2948 18.0061C8.36749 18.004 6.50091 17.3316 5.01505 16.104C3.5292 14.8765 2.51661 13.1704 2.15089 11.2781C1.78516 9.38577 2.08907 7.42518 3.01051 5.73241C3.93196 4.03963 5.41355 2.72009 7.20131 2C6.59422 3.50964 6.44421 5.16442 6.76991 6.75862C7.09561 8.35283 7.88266 9.81616 9.03323 10.9667C10.1838 12.1173 11.6471 12.9043 13.2414 13.23Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "Moon",
);
