import { DialogActions, Button, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OnboardingFormValues } from "./utils";
import { useFormContext } from "react-hook-form";
import { LoadingButton } from "src/components/atoms/loading-button/loading-button";

interface Props {
  onPreviousStep?: () => void;
  onNextStep?: () => void;
  onSubmit?: (data: OnboardingFormValues) => void;
  onStart?: () => void;
  isFormSubmitting?: boolean;
}

export const OnboardingDialogActions = ({
  onNextStep,
  onPreviousStep,
  onSubmit,
  onStart,
  isFormSubmitting,
}: Props) => {
  const { t } = useTranslation();

  const { handleSubmit } = useFormContext<OnboardingFormValues>();

  return (
    <>
      <Divider sx={{ width: "100%" }} />
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: onStart ? "center" : "flex-end",
          py: 4,
          px: 4.3,
        }}>
        <Divider />
        {onPreviousStep ? (
          <Button onClick={onPreviousStep}>{t("onboarding:actions.previous")}</Button>
        ) : null}
        {onNextStep ? (
          <Button variant="contained" onClick={() => onNextStep()}>
            {t("onboarding:actions.next")}
          </Button>
        ) : null}
        {onSubmit ? (
          <LoadingButton
            loading={isFormSubmitting}
            variant="contained"
            onClick={handleSubmit(onSubmit)}>
            {t("onboarding:actions.save")}
          </LoadingButton>
        ) : null}
        {onStart ? (
          <Button variant="contained" onClick={onStart}>
            {t("onboarding:actions.start")}
          </Button>
        ) : null}
      </DialogActions>
    </>
  );
};
