import { Box, Button, Container, Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";

import { WorkInProgress } from "src/icons/work-in-progress";

export const TechnicalBrake = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ backgroundColor: "background.default", height: "100%", width: "100%" }}>
      <Container
        maxWidth="md"
        sx={{
          px: 5,
          py: 14,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        }}>
        <WorkInProgress />
        <Typography align="center" color="textPrimary" sx={{ my: 2 }} variant="h3">
          <Trans t={t} i18nKey={"common:technicalBrake.title"} shouldUnescape />
        </Typography>
        <Typography align="center" color="textSecondary" variant="body2">
          {t("common:technicalBrake.message")}
        </Typography>
        <Button
          color="primary"
          sx={{ mt: 2 }}
          onClick={() => window.location.reload()}
          variant="outlinedContained">
          {t("common:technicalBrake.tryAgain")}
        </Button>
      </Container>
    </Box>
  );
};
