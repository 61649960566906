import { useQuery } from "@tanstack/react-query";
import { ApiError } from "src/types/interfaces/api-error";
import { parkizaCmsApi } from "src/api/organization/cms";
import { parkizaSystemStatusKeys } from "src/api/query-keys/parkiza-system-status";
import { ParkizaSystemStatus } from "src/types/responses/parkiza-system-status";

export const useApiSystemStatus = (enabled?: boolean, onSuccess?: () => void) => {
  return useQuery<ParkizaSystemStatus, ApiError>({
    queryKey: parkizaSystemStatusKeys.all,
    queryFn: () => parkizaCmsApi.fetchSystemStatus(),
    onSuccess: () => onSuccess?.(),
    retry: false,
    enabled,
    refetchOnWindowFocus: false,
  });
};
