import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { sitesApi } from "src/features/site/api/lib/sites";
import { billingsKeys } from "src/features/site/api/query-keys/billing";
import { siteAdminsProfileKeys } from "src/features/site/api/query-keys/site-admins-profile";
import { sitesKeys } from "src/features/site/api/query-keys/sites";
import { CreateSitePayload } from "src/features/site/types/payloads/sites/create-site-payload";
import { ApiError } from "src/types/interfaces/api-error";

export const useCreateSite = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: CreateSitePayload) => sitesApi.createSite(values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: siteAdminsProfileKeys.all });
      queryClient.invalidateQueries({ queryKey: sitesKeys.all });
      queryClient.invalidateQueries({ queryKey: billingsKeys.all });

      onSuccess?.();
    },
    onError: (error: ApiError) => {
      toast.error(error?.response?.data?.message);
    },
  });
};
