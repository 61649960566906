import { createSvgIcon } from "@material-ui/core";

export const Briefcase = createSvgIcon(
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8736_3433)">
      <path
        d="M1.3125 12.1251V4.50012C1.3125 3.94784 1.76022 3.50012 2.3125 3.50012H11.6875C12.2398 3.50012 12.6875 3.94784 12.6875 4.50012V12.1251C12.6875 12.6774 12.2398 13.1251 11.6875 13.1251H2.3125C1.76022 13.1251 1.3125 12.6774 1.3125 12.1251Z"
        stroke="currentColor"
      />
      <path
        d="M1.3125 6.87512V4.50012C1.3125 3.94784 1.76022 3.50012 2.3125 3.50012H11.6875C12.2398 3.50012 12.6875 3.94784 12.6875 4.50012V6.87512C12.6875 7.42741 12.2398 7.87512 11.6875 7.87512H2.3125C1.76022 7.87512 1.3125 7.42741 1.3125 6.87512Z"
        stroke="currentColor"
      />
      <path d="M3.9375 7.87512V9.18762" stroke="currentColor" strokeLinecap="round" />
      <path d="M10.0625 7.87512V9.18762" stroke="currentColor" strokeLinecap="round" />
      <path
        d="M4.8125 3.50012V1.87512C4.8125 1.32284 5.26022 0.875122 5.8125 0.875122H8.1875C8.73978 0.875122 9.1875 1.32284 9.1875 1.87512V3.50012"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8736_3433">
        <rect width="100%" height="100%" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "Briefcase",
);
