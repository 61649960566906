import { createSvgIcon } from "@material-ui/core";

export const SuccessCircle = createSvgIcon(
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 42 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="21" cy="20.5" r="20.5" fill="#D1FADF" fill-opacity="0.35" />
    <circle cx="21" cy="20.5" r="14.5" fill="#D1FADF" />
    <path
      d="M27.1667 17L19.1458 25.0208L15.5 21.375"
      stroke="#027A48"
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "SuccessCircle",
);
