export const ParkizaLogo = () => {
  return (
    <svg
      width="179"
      height="39"
      viewBox="0 0 179 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.2852 16.067V32.1338H20.5075H21.7299V16.067V0.00019455H20.5075H19.2852V16.067Z"
        fill="black"
      />
      <path
        d="M73.6133 16.1939V32.2607H74.8356H76.058V16.1939V0.127148H74.8356H73.6133V16.1939Z"
        fill="black"
      />
      <path
        d="M127.939 16.1939V32.2607H129.162H130.384V16.1939V0.127148H129.162H127.939V16.1939Z"
        fill="black"
      />
      <path
        d="M46.4502 22.5958V38.6626H47.6726H48.8949V22.5958V6.529H47.6726H46.4502V22.5958Z"
        fill="black"
      />
      <path
        d="M100.777 22.5958V38.6626H102H103.222V22.5958V6.529H102H100.777V22.5958Z"
        fill="black"
      />
      <path
        d="M155.104 22.5958V38.6626H156.327H157.549V22.5958V6.529H156.327H155.104V22.5958Z"
        fill="black"
      />
      <path
        d="M0 19.3959V28.2935H1.5619H3.12381V24.7344V21.1626L5.67718 21.0858C9.26276 20.9834 10.2678 20.6889 11.6803 19.3447C12.7804 18.3077 13.1064 17.5012 13.0928 15.7473C13.0928 14.5695 13.0521 14.3134 12.7397 13.6733C11.9927 12.1626 10.458 11.0873 8.4886 10.6904C7.78235 10.5496 6.55999 10.4984 3.77573 10.4984H0V19.3959ZM8.05399 13.0716C8.638 13.2252 9.42574 13.8269 9.71096 14.3518C10.2542 15.3376 10.0233 17.1555 9.27634 17.8212C8.44786 18.551 8.13548 18.615 5.54136 18.6662L3.12381 18.7174V15.8241V12.9308H5.33763C6.54641 12.9308 7.76877 12.9948 8.05399 13.0716Z"
        fill="black"
      />
      <path
        d="M28.9428 19.2299C27.1228 24.0435 25.6016 28.0506 25.5745 28.1274C25.5202 28.2554 25.8869 28.2938 27.1228 28.2938H28.739L29.4045 26.5015L30.07 24.7092L34.0631 24.7348L38.0561 24.7732L38.708 26.5015L39.3464 28.2298L40.9762 28.2682L42.606 28.3066L39.2513 19.4347L35.883 10.5628L34.0767 10.5243L32.2567 10.4987L28.9428 19.2299ZM35.6386 18.2185C36.4399 20.3949 37.0782 22.2 37.0375 22.2256C37.0103 22.2512 35.6386 22.264 33.9952 22.2512L30.9936 22.2128L32.5148 18.1545C33.3432 15.9269 34.0631 14.1346 34.1038 14.1858C34.1446 14.237 34.8372 16.0549 35.6386 18.2185Z"
        fill="black"
      />
      <path
        d="M164.759 19.2294C162.939 24.043 161.418 28.0501 161.391 28.1269C161.337 28.2549 161.703 28.2934 162.939 28.2934H164.555L165.221 26.501L165.886 24.7087L169.879 24.7343L173.873 24.7727L174.524 26.501L175.163 28.2293L176.793 28.2677L178.422 28.3062L175.068 19.4342L171.699 10.5623L169.893 10.5239L168.073 10.4983L164.759 19.2294ZM171.455 18.218C172.256 20.3944 172.895 22.1995 172.854 22.2251C172.827 22.2507 171.455 22.2635 169.812 22.2507L166.81 22.2123L168.331 18.154C169.16 15.9264 169.879 14.1341 169.92 14.1853C169.961 14.2365 170.654 16.0544 171.455 18.218Z"
        fill="black"
      />
      <path
        d="M54.3271 19.5224V28.4199H55.8891H57.451V24.8353V21.2379L58.619 21.2763L59.8006 21.3147L61.9737 24.8609L64.1468 28.4199H65.9667H67.7867L67.5829 28.0615C67.4743 27.8694 66.4285 26.2179 65.274 24.3872L63.1689 21.0714L63.9838 20.7898C66.1025 20.0601 67.3249 18.511 67.4607 16.3346C67.6509 13.5053 65.8988 11.4314 62.8157 10.8169C62.1095 10.676 60.9007 10.6248 58.1029 10.6248H54.3271V19.5224ZM62.3947 13.2109C63.7801 13.6077 64.6085 15.0928 64.269 16.6035C64.0653 17.5508 63.6578 18.1141 62.9108 18.5366C62.2861 18.8695 62.2046 18.8823 59.8685 18.9207L57.451 18.9719V16.0146V13.0572H59.6376C60.9822 13.0572 62.0552 13.1213 62.3947 13.2109Z"
        fill="black"
      />
      <path
        d="M81.627 19.5224V28.4199H83.1889H84.7508L84.7779 24.4896L84.8187 20.5594L88.4178 24.4896L92.017 28.4199H94.0407H96.0644L95.7927 28.1255C95.6433 27.9718 93.7555 25.9747 91.6231 23.6959L87.7387 19.548L88.0511 19.2151C88.3228 18.9463 95.0593 11.585 95.7248 10.8425C95.915 10.6376 95.8199 10.6248 93.9728 10.6376H92.017L88.4178 14.6447L84.8187 18.6518L84.7779 14.6447L84.7508 10.6248H83.1889H81.627V19.5224Z"
        fill="black"
      />
      <path
        d="M110.148 11.841V13.0444L112.091 13.0829L114.019 13.1213L114.06 19.548L114.087 25.9875H112.118H110.148V27.2037V28.4199H115.649H121.15V27.2037V25.9875H119.18H117.211V19.5224V13.0572H119.18H121.15V11.841V10.6248H115.649H110.148V11.841Z"
        fill="black"
      />
      <path
        d="M136.36 11.905V13.1853L140.707 13.2109L145.053 13.2493L140.707 19.7016L136.36 26.1539V27.2933V28.4199H142.608H148.869L148.828 27.1653L148.788 25.9235L144.482 25.8851C142.105 25.8723 140.163 25.8211 140.163 25.7827C140.163 25.7315 142.119 22.7997 144.509 19.2663L148.856 12.8268V11.7258V10.6248H142.608H136.36V11.905Z"
        fill="black"
      />
    </svg>
  );
};
