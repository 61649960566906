import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en";
import pl from "./locales/pl";

const DEFAULT_LNG = ["en", "pl"];

const ns = [...Object.getOwnPropertyNames(en), ...Object.getOwnPropertyNames(pl)];

export const resources = {
  en,
  pl,
} as const;

i18next.use(initReactI18next).init({
  fallbackLng: DEFAULT_LNG,
  ns,
  resources,
});

export default i18next;
