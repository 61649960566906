import { AxiosResponse } from "axios";
import { refreshSession } from "src/api/services/supabase/auth";

// Function that will be called to refresh access token
export const refreshAccessTokenLogic = async (failedRequest: { response: AxiosResponse }) => {
  const { data } = await refreshSession();

  if (data.session?.access_token) {
    failedRequest.response.config.headers.Authorization = `Bearer ${data.session.access_token}`;
  }

  return Promise.resolve();
};
