import { BlacklistedPlatesIcon } from "src/icons/navigation-icons/blacklisted-plates-icon";
import { ChargingSpotsIcon } from "src/icons/navigation-icons/charging-spots-icon";
import { EmployeeIcon } from "src/icons/navigation-icons/employees-icon";
import { SettingsIcon } from "src/icons/navigation-icons/settings-icon";
import { SitesDetailsIcon } from "src/icons/navigation-icons/sites-details-icon";
import { SpotsIcon } from "src/icons/navigation-icons/spots-icon";
import { StatisticIcon } from "src/icons/navigation-icons/statistic-icon";
import { RouteTitle, SidebarRouteTitle } from "src/types/enum/route-title";
import { RoutesGroup } from "src/types/interfaces/routes-group";

export const getRoutes = (hasAccessToEVChargers: boolean): RoutesGroup[] => [
  {
    title: SidebarRouteTitle.PARKING_SETUP,
    routes: [
      {
        icon: SpotsIcon,
        title: RouteTitle.PARKING_SPOTS,
        href: "/resource/spots",
        id: "parking_spots_navigation_link",
      },
      ...(hasAccessToEVChargers
        ? [
            {
              icon: ChargingSpotsIcon,
              title: RouteTitle.CHARGING_SPOTS,
              href: "/ev-chargers",
              id: "ev_chargers_navigation_link",
            },
          ]
        : []),
      {
        icon: EmployeeIcon,
        title: RouteTitle.TENANTS,
        href: "/tenants",
        id: "tenants_navigation_link",
      },
      {
        icon: SitesDetailsIcon,
        title: RouteTitle.SITE_MANAGEMENT,
        href: "/site-management",
        id: "site_management_navigation_link",
      },
    ],
  },
  {
    title: SidebarRouteTitle.DATA_AND_ISSUE,
    routes: [
      {
        icon: StatisticIcon,
        title: RouteTitle.STATISTICS,
        href: "/statistics",
        id: "statistics_navigation_link",
      },
      {
        icon: BlacklistedPlatesIcon,
        title: RouteTitle.BLACKLIST,
        href: "/blacklist",
        id: "blacklisted_plates_navigation_link",
      },
    ],
  },
  {
    title: SidebarRouteTitle.SUPPORT_AND_SETTINGS,
    routes: [
      //? TODO: disable billing data fetching because Salesforce throws errors because it is not paid
      // ...(!isLegacyAgreement
      //   ? [
      //       {
      //         icon: BillingIcon,
      //         title: RouteTitle.BILLING,
      //         href: "/billing",
      //         id: "billing_navigation_link",
      //       },
      //     ]
      //   : []),
      {
        icon: SettingsIcon,
        title: RouteTitle.SETTINGS,
        href: "/settings",
        id: "settings_navigation_link",
      },
    ],
  },
];
