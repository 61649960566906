import { FC, ReactNode } from "react";
import { Button, ButtonProps, CircularProgress } from "@material-ui/core";

interface Props extends ButtonProps {
  loading: boolean;
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
}

export const LoadingButton: FC<Props> = ({ children, onClick, loading, disabled, ...other }) => {
  return (
    <Button
      sx={{
        position: "relative",
        "&:disabled": {
          cursor: "not-allowed",
          pointerEvents: "auto",
        },
      }}
      onClick={onClick}
      {...other}
      disabled={loading || disabled}>
      {loading ? (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            translate: "-50% -50%",
          }}
          size={16}
          color="inherit"
        />
      ) : null}
      <span
        style={{
          display: "flex",
          alignItems: "center",
          visibility: loading ? "hidden" : "visible",
        }}>
        {children}
      </span>
    </Button>
  );
};
