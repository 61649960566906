export const FEE_PER_SINGLE_SPOT = 1;

export const FEE_PER_SINGLE_USER = 3;

export const calculateMonthlyFeeBasedOnAmountOfSpots = (numberOfSpots: number) => {
  return numberOfSpots * FEE_PER_SINGLE_SPOT;
};

export const calculateMonthlyFeeBasedOnAmountOfUsers = (numberOfUsers: number) => {
  return numberOfUsers * FEE_PER_SINGLE_USER;
};
