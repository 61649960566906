import axios from "axios";

import { attachAcceptLanguage } from "src/api/interceptors/attach-accept-language";
import { attachAccessToken } from "src/api/interceptors/attach-access-token";
import { attachSiteToken } from "../interceptors/attach-site-token";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { refreshAccessTokenLogic } from "src/api/interceptors/refresh-access-token-logic";

const siteApi = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/site-admin-api`,
});

createAuthRefreshInterceptor(siteApi, refreshAccessTokenLogic);

siteApi.interceptors.request.use(attachAccessToken);
siteApi.interceptors.request.use(attachSiteToken);
siteApi.interceptors.request.use(attachAcceptLanguage);

export default siteApi;
