import tenantApiV1 from "src/api/clients/tenant-api-v1";
import { TenantEvChargersModuleAccessResponse } from "../types/ev-chargers-module-access.response";

class tenantEvChargersModuleAccess {
  async getAccess(): Promise<TenantEvChargersModuleAccessResponse> {
    const { data } = await tenantApiV1.get<TenantEvChargersModuleAccessResponse>(
      "/ev-chargers/organization",
    );

    return data;
  }
}

export const tenantEvChargersModuleAccessApi = new tenantEvChargersModuleAccess();
