import { useQuery } from "@tanstack/react-query";
import { siteAdminsProfileKeys } from "src/features/site/api/query-keys/site-admins-profile";
import { siteAdminsApi } from "src/features/site-management/api/lib/site-admins";
import { SiteAdminProfile } from "src/features/site-management/types/responses/site-admin-profile";
import { ApiError } from "src/types/interfaces/api-error";
import useStore from "src/hooks/use-store";

export const useSiteAdminsProfile = (enabled?: boolean, retry?: boolean) => {
  const { user } = useStore();

  return useQuery<SiteAdminProfile, ApiError>({
    queryKey: siteAdminsProfileKeys.detail(),
    queryFn: siteAdminsApi.fetchSiteAdminsProfile,
    enabled: enabled ?? true,
    retry,
    onError: error => {
      if (error.response.status === 401) {
        user.clearSiteToken();
      }
    },
  });
};
