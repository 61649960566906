import { DialogContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OnboardingDialogWrapper } from "src/components/organisms/onboarding/onboarding-dialog-wrapper";
import { OnboardingFormValues } from "src/components/organisms/onboarding/utils";

interface Props {
  onNextStep: () => Promise<void>;
  onSubmit: (data: OnboardingFormValues) => Promise<void>;
  currentStep: number;
  stepsLength: number;
  isFormSubmitting?: boolean;
}

export const PlanStep = ({
  onNextStep,
  onSubmit,
  currentStep,
  stepsLength,
  isFormSubmitting,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <OnboardingDialogWrapper.Logo />
      <DialogContent sx={{ px: 2.75 }}>
        <OnboardingDialogWrapper.Title text={t("onboarding:plan.title")} />
        <OnboardingDialogWrapper.Plan />
        <OnboardingDialogWrapper.Annotation text={t("onboarding:plan.annotation")} />
        <OnboardingDialogWrapper.StepIndicator currentStep={currentStep} totalSteps={stepsLength} />
      </DialogContent>
      <OnboardingDialogWrapper.Actions
        onNextStep={onNextStep}
        onSubmit={onSubmit}
        isFormSubmitting={isFormSubmitting}
      />
    </>
  );
};
