export const SitesDetailsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.2222 20V5.77778C18.2222 4.79594 17.4263 4 16.4444 4H7.55556C6.57372 4 5.77778 4.79594 5.77778 5.77778V20M18.2222 20H20M18.2222 20H13.7778M5.77778 20H4M5.77778 20H10.2222M13.7778 20V15.5556C13.7778 15.0646 13.3798 14.6667 12.8889 14.6667H11.1111C10.6202 14.6667 10.2222 15.0646 10.2222 15.5556V20M13.7778 20H10.2222M9.33333 7.55554H11.1111M9.33333 11.1111H11.1111M12.8889 7.55554C13.5832 7.55554 13.9724 7.55554 14.6667 7.55554M12.8889 11.1111H14.6667"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
