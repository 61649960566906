import { FC, ForwardedRef, forwardRef } from "react";

import type { FilledTextFieldProps } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import type { SxProps } from "@material-ui/system";

interface InputFieldProps extends Omit<FilledTextFieldProps, "variant"> {
  InputProps?: FilledTextFieldProps["InputProps"];
  sx?: SxProps;
  min?: number;
  max?: number;
  labelSize?: "small" | "regular";
}

export const InputField: FC<InputFieldProps> = forwardRef(
  (props, ref: ForwardedRef<HTMLInputElement>) => {
    const { InputProps, sx, max, min, InputLabelProps, labelSize = "regular", ...other } = props;

    return (
      <TextField
        {...other}
        ref={ref}
        inputProps={{
          ...props.inputProps,
          min,
          max,
          sx: {
            fontSize: 14,
            ...props.inputProps?.sx,
            alignItems: "center",
            display: "flex",
            height: "unset",
            lineHeight: 1.6,
            px: 1.5,
            py: 0.75,
            "&.MuiInputBase-inputAdornedStart": {
              pl: 0,
            },
            "&.Mui-disabled": {
              cursor: "not-allowed",
            },
          },
        }}
        variant="filled"
        SelectProps={{
          MenuProps: {
            style: {
              maxHeight: 400,
            },
          },
        }}
        InputLabelProps={{
          shrink: true,
          sx: {
            color: "text.primary",
            fontSize: labelSize === "regular" ? 14 : 12,
            fontWeight: 500,
            mb: 0.5,
            position: "relative",
            transform: "none",
            whiteSpace: "pre-wrap",
          },
        }}
        sx={{
          "& .MuiFilledInput-root": {
            backgroundColor: "background.paper",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "neutral.300",
            borderRadius: 1,
            boxShadow: "0px 1px 2px 0px rgba(9, 30, 66, 0.08)",
            overflow: "hidden",
            p: 0,
            transition: theme => theme.transitions.create(["border-color", "box-shadow"]),
            "&:before": {
              borderBottom: 0,
            },
            "&:hover": {
              backgroundColor: "background.paper",
            },
            "&.Mui-focused": {
              backgroundColor: "background.paper",
              borderColor: "action.focus",
              boxShadow: theme => `${theme.palette.action.focus} 0 0 0 0.2rem`,
            },
            "&.Mui-disabled": {
              backgroundColor: "action.disabledBackground",
              boxShadow: "none",
              borderColor: alpha("#D6DBE1", 0.5),
            },
            ".MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)":
              {
                mt: 0,
                ml: 1.5,
              },
          },
          ...sx,
        }}
        FormHelperTextProps={{
          sx: {
            ml: 0,
          },
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          ...InputProps,
          disableUnderline: true,
        }}
      />
    );
  },
);
