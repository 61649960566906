import { Typography, useTheme } from "@material-ui/core";
import { FC } from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { lightError, lightNeutral, lightText } from "src/colors";

interface Props extends PhoneInputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  name?: string;
  required?: boolean;
  country?: string;
  preferredCountries?: string[];
}

const PhoneNumberInput: FC<Props> = ({
  label,
  value,
  onChange,
  onBlur,
  helperText,
  error,
  name,
  disabled,
  required,
  country,
  preferredCountries,
  ...other
}) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          mt: 0.5,
          color: error ? lightError.main : "text.primary",
          fontSize: 14,
          fontWeight: 500,
          mb: 0.5,
          position: "relative",
          transform: "none",
        }}>
        {label}
        {required ? " *" : ""}
      </Typography>
      <PhoneInput
        {...other}
        disabled={disabled}
        country={country ?? "pl"}
        value={value}
        onBlur={onBlur}
        preferredCountries={preferredCountries ?? ["pl", "gb", "us"]}
        onChange={onChange}
        inputProps={{
          name,
          required,
        }}
        dropdownStyle={{
          color: lightText.primary,
        }}
        inputStyle={{
          alignItems: "center",
          display: "flex",
          fontSize: 14,
          height: "unset",
          paddingTop: "5px",
          paddingBottom: "4px",
          color: mode === "dark" ? "#d8dade" : "#000",
          backgroundColor: "transparent",
          borderWidth: 1,
          borderStyle: "solid",
          width: "100%",
          borderColor: mode === "dark" ? "rgba(255,255,255,0.35)" : lightNeutral[300],
          borderRadius: 10,
          boxShadow: "0px 1px 2px 0px rgba(9, 30, 66, 0.08)",
        }}
      />
      {error ? (
        <Typography variant="caption" sx={{ color: lightError.main, ml: 0 }}>
          {helperText}
        </Typography>
      ) : null}
    </>
  );
};

export default PhoneNumberInput;
