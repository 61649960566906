import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { sitesApi } from "src/features/site/api/lib/sites";
import { ApiError } from "src/types/interfaces/api-error";
import { SiteOnboardingPayload } from "src/types/payloads/onboarding-payload";

export const useCompleteSiteRegistration = (onSuccess?: () => void) => {
  return useMutation({
    mutationFn: (values: SiteOnboardingPayload) => sitesApi.completeRegistration(values),
    onSuccess: () => {
      onSuccess?.();
    },
    onError: (error: ApiError) => {
      toast.error(error?.response?.data?.message);
    },
  });
};
