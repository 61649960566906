import { Typography } from "@material-ui/core";
import { t } from "i18next";

interface Props {
  currentStep: number;
  totalSteps: number;
}

export const OnboardingDialogStepIndicator = ({ currentStep, totalSteps }: Props) => {
  return (
    <Typography align="center" color="black" sx={{ fontSize: "13px", pb: 1.875, pt: 3.875 }}>
      {t("onboarding:step", { currentStep: currentStep, steps: totalSteps })}
    </Typography>
  );
};
