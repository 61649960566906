import { makeAutoObservable } from "mobx";

export type ViewContext = "site" | "tenant";

enum LocalStorageKeys {
  ORGANIZATION_ID = "userOrganizationId",
  SITE_ID = "siteToken",
  VIEW_CONTEXT = "viewContext",
}

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  userOrganizationId: string | null =
    localStorage.getItem(LocalStorageKeys.ORGANIZATION_ID) ?? null;

  isChangingOrganization: boolean = false;

  isLoadingViewContextAccess: boolean = true;

  siteToken: string | null = localStorage.getItem(LocalStorageKeys.SITE_ID) ?? null;

  viewContext: ViewContext =
    (localStorage.getItem(LocalStorageKeys.VIEW_CONTEXT) as ViewContext) ?? "site";

  setUserOrganizationId = (userOrganizationId: string) => {
    this.userOrganizationId = userOrganizationId;

    localStorage.setItem(LocalStorageKeys.ORGANIZATION_ID, userOrganizationId);
  };

  setIsChangingOrganization = (isChanging: boolean) => {
    this.isChangingOrganization = isChanging;
  };

  setIsLoadingViewContextAccess = (isChanging: boolean) => {
    this.isLoadingViewContextAccess = isChanging;
  };

  clearUserOrganizationId = () => {
    this.userOrganizationId = null;

    localStorage.removeItem(LocalStorageKeys.ORGANIZATION_ID);
  };

  setSiteToken = (siteToken: string) => {
    this.siteToken = siteToken;

    localStorage.setItem(LocalStorageKeys.SITE_ID, siteToken);
  };

  clearSiteToken = () => {
    this.siteToken = null;

    localStorage.removeItem(LocalStorageKeys.SITE_ID);
  };

  setViewContext = (context: ViewContext) => {
    this.viewContext = context;

    localStorage.setItem(LocalStorageKeys.VIEW_CONTEXT, context);
  };

  clearViewContext = () => {
    localStorage.removeItem(LocalStorageKeys.VIEW_CONTEXT);
  };
}

export default UserStore;
