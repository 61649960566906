import { ParkizaSystemStatus } from "src/types/responses/parkiza-system-status";
import cmsApi from "../clients/cms";

class ParkizaCmsApi {
  async fetchSystemStatus(): Promise<ParkizaSystemStatus> {
    const { data } = await cmsApi.get<ParkizaSystemStatus>(`/system-status`);

    return data;
  }
}

export const parkizaCmsApi = new ParkizaCmsApi();
