import { createSvgIcon } from "@material-ui/core";

export const WorkInProgress = createSvgIcon(
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3108 2.95512C17.6199 1.68163 19.4311 1.68163 19.7402 2.95512C19.94 3.77779 20.8825 4.16819 21.6054 3.7277C22.7245 3.04581 24.0052 4.32649 23.3233 5.4456C22.8828 6.16853 23.2732 7.11104 24.0959 7.31076C25.3694 7.61993 25.3694 9.43107 24.0959 9.74024C23.2732 9.93996 22.8828 10.8825 23.3233 11.6054C24.0052 12.7245 22.7245 14.0052 21.6054 13.3233C20.8825 12.8828 19.94 13.2732 19.7402 14.0959C19.4311 15.3694 17.6199 15.3694 17.3108 14.0959C17.111 13.2732 16.1685 12.8828 15.4456 13.3233C14.3265 14.0052 13.0458 12.7245 13.7277 11.6054C14.1682 10.8825 13.7778 9.93996 12.9551 9.74024C11.6816 9.43107 11.6816 7.61993 12.9551 7.31076C13.7778 7.11104 14.1682 6.16853 13.7277 5.4456C13.0458 4.32649 14.3265 3.04581 15.4456 3.7277C16.1685 4.16819 17.111 3.77778 17.3108 2.95512ZM18.5255 9.8306C19.2463 9.8306 19.8306 9.24629 19.8306 8.5255C19.8306 7.80471 19.2463 7.2204 18.5255 7.2204C17.8047 7.2204 17.2204 7.80471 17.2204 8.5255C17.2204 9.24629 17.8047 9.8306 18.5255 9.8306Z"
      fill="#A0A19B"
    />
    <path
      d="M30.2667 25.3337L18.1333 13.2003C19.3333 10.1337 18.6667 6.53367 16.1333 4.00034C13.4667 1.33367 9.46667 0.800342 6.26667 2.26701L12 8.00034L8 12.0003L2.13333 6.26701C0.533334 9.46701 1.2 13.467 3.86667 16.1337C6.4 18.667 10 19.3337 13.0667 18.1337L25.2 30.267C25.7333 30.8003 26.5333 30.8003 27.0667 30.267L30.1333 27.2003C30.8 26.667 30.8 25.7337 30.2667 25.3337Z"
      fill="#212831"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.73155 14.3905C8.18164 12.5365 10.8184 12.5365 11.2685 14.3905C11.5592 15.5881 12.9313 16.1565 13.9838 15.5152C15.613 14.5225 17.4775 16.387 16.4848 18.0162C15.8435 19.0687 16.4119 20.4408 17.6095 20.7315C19.4635 21.1816 19.4635 23.8184 17.6095 24.2684C16.4119 24.5592 15.8435 25.9313 16.4848 26.9838C17.4775 28.613 15.613 30.4775 13.9838 29.4848C12.9313 28.8435 11.5592 29.4119 11.2685 30.6095C10.8184 32.4635 8.18164 32.4635 7.73155 30.6095C7.4408 29.4119 6.06866 28.8435 5.0162 29.4848C3.38697 30.4775 1.52252 28.613 2.51523 26.9838C3.15651 25.9313 2.58815 24.5592 1.39049 24.2684C-0.463497 23.8184 -0.463498 21.1816 1.39049 20.7315C2.58815 20.4408 3.15651 19.0687 2.51523 18.0162C1.52252 16.387 3.38696 14.5225 5.0162 15.5152C6.06866 16.1565 7.4408 15.5881 7.73155 14.3905ZM9.5 24.4C10.5493 24.4 11.4 23.5493 11.4 22.5C11.4 21.4507 10.5493 20.6 9.5 20.6C8.45066 20.6 7.6 21.4507 7.6 22.5C7.6 23.5493 8.45066 24.4 9.5 24.4Z"
      fill="#A0A19B"
    />
  </svg>,
  "WorkInProgress",
);
